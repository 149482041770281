import * as pako from "pako";

const appURL = process.env.REACT_APP_URL ?? "www.gethai.com";
const formatCode = (code) =>
  code ? code.slice(0, 3) + " " + code.slice(3, 6) : "";

const unzip = (base64str) => {
  const strData = atob(base64str);
  // Convert binary string to character-number array
  const charData = strData.split("").map((x) => {
    return x.charCodeAt(0);
  });
  // Turn number array into byte-array
  const binData = new Uint8Array(charData);
  return JSON.parse(pako.inflate(binData, { to: "string" }));
};

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const productInfoLink = process.env.REACT_APP_DYNAMICLINK_PRODUCTINFO;

export default {
  appURL,
  formatCode,
  unzip,
  isMobile,
  productInfoLink
};
