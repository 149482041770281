export const textConstant = {
    productId: "Product ID:",
    verificationCode: "Verification Code:",
    error: "error",
    commonError: "Your request has failed, please try again later.",
    dateTimeFormat: "MM/dd/yyyy h:mm a",
    removeSuccessTitle: "",
    removeSuccessDesc: "User has been removed successfully.",
    resetSuccessTitle: "Reset request successful!",
    resetSuccessDesc: "Showerhead will reset when the next user pairs.",
    removeUserDesc: "Are you sure you want to remove this user from shower device?",
    ok: "OK",
    cancel:"Cancel",
    resetTitle:"This will reset the shower to its default factory settings and all the paired users will be removed from the device.",
    resetDesc: "Are you sure you want to reset this shower device?",
    yes:"Yes",
    no:"No"
}