import React from "react";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import Codes from "./screens/Codes";
import PairedDevice from "./screens/PairedDevice";
import PairedDeviceInfo from "./screens/PairedDeviceInfo";
import PairedDeviceDashboard from "./screens/PairedDeviceDashboard";
import History from "./screens/History";
import PublishFirmware from "./screens/PublishFirmware";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
  Redirect,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Layout from "./components/Layout";
import Provider from "./services/Provider";

import "./styles/root.css";
import AddDevice from "./screens/AddDevice";
import DataDashboard from "./screens/DataDashboard";
import Help from "./screens/Help";

import { Box } from "@mui/material";
import Logo from "./logo.png";
import FindProductInfo from "./screens/FindProductInfo";

//Theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#fefefe",
    },
    secondary: {
      main: "#002c9a",
    },
  },
  typography: {
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    button: {
      textTransform: "none",
    },
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
});

Provider.configure();

function App() {
  const [isInternalGroup, setInternalGroup] = React.useState(true);
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(async () => {
    return onAuthUIStateChange(async (nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      if (authData) {
        const groups = await Provider.groups();
        setInternalGroup(groups !== undefined && groups.length > 0);
      }
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Layout isInternalGroup={isInternalGroup}>
          <Switch>
            <Route exact path="/">
              {isInternalGroup ? <Codes /> : !document.referrer ? <Redirect to="/manage" /> : <Redirect to="/product" />}
            </Route>
            <Route exact path="/add">
              <AddDevice />
            </Route>
            <Route path="/paireddevice">
              <PairedDevice />
            </Route>
            <Route path="/manage">
              <PairedDeviceDashboard />
            </Route>
            <Route path="/history">
              <History />
            </Route>
            <Route path="/publishfirmware">
              <PublishFirmware />
            </Route>
            <Route path="/data">
              <DataDashboard />
            </Route>
            <Route path="/help">
              <Help />
            </Route>
            <Route path="/product">
              <FindProductInfo />
            </Route>
            <Route path="/signout">
              <></>
            </Route>
          </Switch>
        </Layout>
      </HashRouter>
    </ThemeProvider>
  ) : (
    <AmplifyAuthenticator>
      <div
        slot="sign-in"
        style={{ position: "absolute", zIndex: 1, top: "5%" }}
      >
        <Box sx={{ height: 24 }} component="img" src={Logo} />
      </div>
      <AmplifySignIn slot="sign-in" hideSignUp={true}></AmplifySignIn>
    </AmplifyAuthenticator>
  );
}

export default App;
