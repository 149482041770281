import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  TextField,
  Button,
  Box,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PreviewIcon from "@mui/icons-material/Preview";
import Loader from "../components/Loader";
import Provider from "../services/Provider";
import AlertDisplay from "../components/Alert";
import TableView from "../components/TableView";
import HistroyProvider from "../services/HistroyProvider";

const useStyle = makeStyles({
  disabledButton: {
    backgroundColor: "#cfcfcf !important",
    pointerEvents: "none",
  },
  emailField: {
    width: "50%",
  },
});

export default function History() {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [rows, setRows] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const calculatedHeight = () => `${window.innerHeight - 100}px`;
  const innerHeight = calculatedHeight();
  const [height, setHeight] = useState(innerHeight);
  const pageSize = 25;

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const resizeWindow = () => {
    setHeight(calculatedHeight());
  };

  const handleMessage = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
  };

  const downloadHistory = (e) => {
    e.preventDefault();
    setLoading(true);
    handleMessage("", "");
    setRows([]);
    Provider.downloadHistory(email)
      .then((response) => {
        if (response && response.length > 0) {
          if (email === "") {
            Provider.saveFile(
              new Blob([response.join("\r\n")], { type: "text/csv" }),
              "history.csv"
            );
            handleMessage(
              "success",
              "Entire shower history is downloaded as csv. It will not be visible in table."
            );
          } else {
            setRows(HistroyProvider.rows(response));
            if (checked) {
              Provider.saveFile(
                new Blob([response.join("\r\n")], { type: "text/csv" }),
                "history.csv"
              );
            }
          }
        } else {
          handleMessage("error", "Failed to download history");
        }
      })
      .catch((error) => {
        handleMessage("error", "Failed to download history");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handTextChange = (e) => {
    e.preventDefault();
    handleMessage("", "");
    setEmail(e.target.value);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div
      style={{
        paddingBottom: "1.5rem",
        width: "100%",
        maxHeight: height,
        height: "auto",
        overflowY: "auto",
      }}
    >
   <Container sx={{ margin: "0 !important" }} maxWidth="xl">
      {loading ? <Loader /> : ""}
      <>
        <Typography
          color="secondary"
          gutterBottom
          sx={{
            marginBottom: "0.5rem",
          }}
        >
          Download Shower History
        </Typography>
        <form noValidate autoComplete="off" onSubmit={downloadHistory}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            autoFocus
            color="secondary"
            required
            size="small"
            value={email}
            className={classes.emailField}
            onChange={handTextChange}
          />
          <br />
          <Grid container sx={{ mt: 1 }} rowSpacing={1}>
            <Grid item>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                endIcon={<PreviewIcon />}
              >
                Show
              </Button>
            </Grid>
            <Grid item xs={8}>
              <FormGroup sx={{ ml: 2, mt: 0.8 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      color="secondary"
                    />
                  }
                  label="Download as csv"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </>
      {(message && severity && email === "") || rows.length === 0 ? (
        <Box sx={{ mt: 2 }}>
          <AlertDisplay severity={severity} message={message} />
        </Box>
      ) : null}

      {email !== "" && rows.length > 0 ? (
        <TableView
          rows={rows}
          columns={HistroyProvider.columns}
          pageSize={pageSize}
        />
      ) : null}
    </Container>
    </div>
 
  );
}
