import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  Card,
  Box,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { QRCode } from "react-qr-svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Loader from "../components/Loader";
import Provider from "../services/Provider";
import AlertDisplay from "../components/Alert";
import TableView from "../components/TableView";
import Modal from "../components/Modal";
import Util from "../util/Util";
import { styled } from "@mui/material/styles";
import { textConstant } from "../util/constants";

const useStyle = makeStyles({
  disabledButton: {
    backgroundColor: "#cfcfcf !important",
    pointerEvents: "none",
  },
  emailField: {
    width: "50%",
  },
});

export default function PairedDevice() {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [btnDisabled, setDisabled] = useState(true);
  const [devices, setDevices] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isResetModal, setResetModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [isCommonModal, setCommonModal] = useState(false);
  const [commonModalTitle, setCommonModalTitle] = useState("");
  const [commonModalText, setCommonModalText] = useState("");
  const calculatedHeight = () => `${window.innerHeight - 100}px`;
  const innerHeight = calculatedHeight();
  const [height, setHeight] = useState(innerHeight);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const resizeWindow = () => {
    setHeight(calculatedHeight());
  };
  
  const searchOptions = [
    { value: "email", label: "Email" },
    { value: "serial", label: "Product ID" },
    {
      value: "code",
      label: "Verification Code",
    },
  ];
  const [searchCriteria, setSearchCriteria] = React.useState(searchOptions[0]);
  const pageSize = 25;
  const columns = [
    {
      field: "id",
      headerName: "ID",
      hideable: false,
      headerAlign: "center",
      align: "center",
      hide: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "left",
    },
  ];

  const rows = (items) => {
    try {
      if (items && items.length > 0) {
        return items.map((item) => {
          return {
            id: Math.random(),
            email: item,
          };
        });
      }
    } catch (error) {
      console.log("Failed to create table rows data " + error);
    }
  };

  const handleMessage = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
  };

  const lookUp = async (e, isResetCompleted) => {
    e?.preventDefault();
    setLoading(true);
    handleMessage("", "");
    const data = {
      email: "",
      serial: "",
      code: "",
    };
    data[searchCriteria.value] = fieldValue;
    Provider.lookupDevices(data)
      .then((response)  => {
        if (response) {
          setDevices(response);
          if (response.length === 0 && !isResetCompleted) {
            onError();
          }
        }else{
          if (!response && !isResetCompleted) {
            onError();
          }
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onError= () => {
    setDevices([]);
    handleMessage("error", "Failed to lookup devices");
  }

  const handTextChange = (e) => {
    e.preventDefault();
    if (e.target.value.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setFieldValue(e.target.value);
    handleMessage("", "");
  };

  const handleChange = (event) => {
    event.preventDefault();
    setDisabled(true);
    setFieldValue("");
    var selectedOption = searchOptions.find(
      (val) => val.value === event.target.value
    );
    setSearchCriteria(selectedOption);
    setDevices([]);
    handleMessage("", "");
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #c4c4c4",
      color: theme.palette.text.primary,
      padding: "10px 26px 5px 12px",
    },
  }));

  const qrCodeContent = (device) => {
    return (
      device && (
        <div id={`device-${device.serial}`}>
          <div>
            <div id="device-serial">Product ID:</div>
            <div className="code">{device.serial}</div>
          </div>
          <div>
            <div id="device-code">Verification Code:</div>
            <div>{Util.formatCode(device.code)}</div>
            <QRCode
              fgColor="#002C9A"
              style={{ width: 128, marginTop: 8 }}
              level="H"
              value={encodeURI(`${Util.appURL}?code=${device.code}`)}
            ></QRCode>
          </div>
          <Button type="submit" size="small" color="secondary" variant="contained" sx={{ mt: 2 }} onClick={() => onReset(device)}>
              Reset Showerhead
            </Button>
        </div>
      )
    );
  };

  const PairedDevice = (devices) => {
    return (
      <>
        {devices.devices !== undefined && devices.devices.length > 0 ? (
          <Container sx={{ py: 2, ml: -3 }}>
            <Grid sx={{ mt: -1 }} container spacing={4}>
              {devices.devices.map((device) => {
                return (
                  <Grid item key={device.serial}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        {qrCodeContent(device)}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        ) : null}
        {devices.devices !== undefined &&
        devices.devices.code &&
        devices.devices.serial ? (
          <Container sx={{ py: 2, ml: -3 }}>
            <Grid sx={{ mt: -1 }} container spacing={4}>
              <Grid item>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    {qrCodeContent(devices.devices)}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {devices.devices.emails && devices.devices.emails.length > 0 ? (
              <TableView
                rows={rows(devices.devices.emails)}
                columns={columns}
                pageSize={pageSize}
              />
            ) : null}
          </Container>
        ) : null}
      </>
    );
  };

  const onReset = (device) => {
    setSelectedDevice(device);
    setResetModal(true);
  }
  
  const resetDevice = () => {
    handleMessage("", "");
    setLoading(true);
    Provider.resetDevice(selectedDevice.serial)
      .then((response) => {
        setCommonModal(true);
        setCommonModalTitle(textConstant.resetSuccessTitle);
        setCommonModalText(textConstant.resetSuccessDesc);
        lookUp(undefined, false);
      })
      .catch((error) => {
        setCommonModal(true);
        setCommonModalTitle("");
        setCommonModalText(textConstant.commonError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        paddingBottom: "1.5rem",
        width: "100%",
        maxHeight: height,
        height: "auto",
        overflowY: "auto",
      }}
    >
      <Container sx={{ margin: "0 !important" }}>
        {loading ? <Loader /> : ""}
        <>
          <Typography
            color="secondary"
            gutterBottom
            sx={{
              marginBottom: "0.5rem",
            }}
          >
            Lookup Paired Devices
          </Typography>
          <form noValidate autoComplete="off" onSubmit={lookUp}>
            <Grid container item xs={12}>
              <TextField
                label={searchCriteria.label}
                variant="outlined"
                type="email"
                autoFocus
                color="secondary"
                required
                value={fieldValue}
                size="small"
                className={classes.emailField}
                onChange={handTextChange}
              />
              <Box sx={{ ml: 2 }}>
                <FormControl fullWidth>
                  <Select
                    id="select-label"
                    value={searchCriteria.value}
                    label={searchCriteria.label}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                  >
                    {searchOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ mt: 2 }}
              className={btnDisabled ? classes.disabledButton : ""}
              endIcon={<RemoveRedEyeIcon />}
            >
              Lookup
            </Button>
          </form>
          <PairedDevice devices={devices} />
        </>
        {message && severity ? (
          <Box sx={{ mt: 2 }}>
            <AlertDisplay severity={severity} message={message} />
          </Box>
        ) : null}

      <Modal
          open={isResetModal}
          onClose={() => setResetModal(false)}
          title={textConstant.resetTitle}
          text={textConstant.resetDesc}
          primaryBtnText={textConstant.ok}
          onPrimaryBtnClicked={() => {
            resetDevice();
          }}
          secondaryBtnText={textConstant.cancel}
          onSecondaryBtnClicked={() => {}}
        />

      <Modal
          open={isCommonModal}
          onClose={() => setCommonModal(false)}
          title={commonModalTitle}
          text={commonModalText}
          primaryBtnText={textConstant.ok}
          onPrimaryBtnClicked={() => {}}
        />
      </Container>
    </div>
  );
}
