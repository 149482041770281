import { Alert, AlertTitle, Stack } from "@mui/material";
const AlertDisplay = (props) => {
  let { severity, message } = props;
  return (
    <>
      {message && severity ? (
        <Stack sx={{ width: "100%" }}>
          <Alert severity={severity}>{message}</Alert>
        </Stack>
      ) : null}
    </>
  );
};

export default AlertDisplay;
