import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Input,
  Tooltip,
} from "@mui/material";
import Loader from "../components/Loader";
import AlertDisplay from "../components/Alert";
import { makeStyles } from "@mui/styles";
import { Alert, Stack } from "@mui/material";
import Provider from "../services/Provider";
import { textConstant } from "../util/constants";
import MUIDataTable from "@gethai/mui-datatables";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

const pageContent = {
  description: `To add more than one code at a time, list all the codes separated by a comma and press "Submit".`,
  successMessage: `All the Verification codes were successfully added.`,
  singleCodeSuccess: `Verification code successfully added.`,
  errorMessage: `are not valid verification codes. Please try again.`,
  alreadyClaimCode: `were already added.`,
  singleAlreadyClaim: `Verification code was already added.`,
  singleCodeError: `is not a valid verification code. Please try again.`,
  partialErrorMessage: `are not valid verification codes. Please try again.`,
  label: `Enter Verification Codes`,
  submit: `Submit`,
  success: `success`,
  error: `error`,
  info: `info`,
  verificationInfo:`This is needed to register and pair the showerhead as well as for the user to access the app. Code can be found on the printed QR code card that came with the original packaging.`,
  unitNoInfo:`Custom ID can be used to indicate where the shower has been installed (e.g. apartment number, hotel unit, building name, etc). Input and edit as needed.`
};

const useStyles = makeStyles({
  disabledButton: {
    backgroundColor: "#cfcfcf !important",
    pointerEvents: "none",
  },
  emailField: {
    width: "100%",
  },
});

export default function AddDevice() {
  const classes = useStyles();
  const [btnDisabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPartialSuccess, setPartialSuccess] = useState(false);
  const [partialSeverity, setPartialSeverity] = useState("");
  const [partialSuccessMessage, setPartialSuccessMessage] = useState("");
  const [partialError, setPartialError] = useState([]);
  const [partialExistingError, setPartialExistingError] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const currentCodeRef = useRef();
  const currentRoomRef = useRef();
  const calculatedPageHeight = () => `${window.innerHeight - 100}px`;
  const innerHeight = calculatedPageHeight();
  const [pageHeight, setPageHeight] = useState(innerHeight);
  const [inputError, setInputError] = useState(false);
  const [isResult, setResult] = useState(false);
  const [isResultError, setResultError] = useState(false);
  const code = useRef();
  const roomId = useRef();

  const handleCodeChange = (e) => {
    code.current = e.target.value;
  };
  const handleRoomIdChange = (e) => {
    roomId.current = e.target.value;
  };

  const data = [
    {
      id: "",
      code: "",
      roomId: "",
      error: "",
    },
  ];
  const [rowsData, setRowData] = useState(data);
  const dataRef = useRef(data);
  const [openPopper, setPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [info, setInfo] = useState('');
  const popperState = useRef();
  const columnsData = [
    {
      name: "id",
      viewColumns: false,
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "code",
      label: "Verification Code",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            style={{
              width: `${columnWidth}px`,
              border: "1px solid rgb(224, 224, 224)",
              padding: "16px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: "0.875rem", paddingTop: "0.2rem" }}>
                {"Verification Code"}
              </div>
              <IconButton
                style={{ padding: 0, paddingLeft: "0.2rem" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if(openPopper && info === pageContent.verificationInfo){
                    setPopper(false);  
                    popperState.current = "";
                  }else{
                    setPopper(true);
                    popperState.current = "CODE";
                    setAnchorEl(e.currentTarget);
                    setInfo(pageContent.verificationInfo);
                  }
                }}
              >
                <InfoOutlinedIcon color="secondary" />
              </IconButton>
            </div>
          </th>
        ),
        setCellProps: (value) => {
          return {
            style: {
              borderRight: "1px solid rgba(224, 224, 224, 1)",
            },
          };
        },
        setCellHeaderProps: (value) => {
          return {
            style: {
              border: "1px solid rgba(224, 224, 224, 1)",
              width: `${columnWidth}px`,
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const onSave = () => {
            currentCodeRef.current = "";
            const currentCode = rowsData.find(
              (data) => data.id === tableMeta.rowData[0]
            );
            currentCode.code = value;
            currentCode.error = undefined;
            code.current = "";
            roomId.current = "";
            setDisabled(false);
          };
          return (
            <>
              {tableMeta.rowData[3] !== undefined &&
                tableMeta.rowData[3] === false && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "0.4rem",
                        color: "green",
                      }}
                    >
                      {value}
                    </div>
                  </div>
                )}
              {(tableMeta.rowData[3] === undefined || tableMeta.rowData[3]) && (
                <div style={{ display: "flex" }}>
                  <TextField
                    variant="standard"
                    error={tableMeta.rowData[3] === true}
                    autoFocus={tableMeta.rowData[0] && rowsData?.length > 1}
                    value={value}
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      updateValue(event.target.value);
                      const val = dataRef.current[tableMeta.rowIndex];
                      if (val) {
                        val.code = event.target.value;
                        val.error = undefined;
                        setRowData([...dataRef.current]);
                      } else {
                        handleCodeChange(event);
                      }
                    }}
                    onPaste={(event) => {
                      event.preventDefault();
                      code.current = event.clipboardData.getData("Text");
                      const codeItems = splitText(code.current);
                      const currentNode = dataRef.current[tableMeta.rowIndex];
                      if (currentNode?.code) {
                        if (codeItems?.length > 1) {
                          dataRef.current = dataRef.current?.filter(
                            (data) => data.id != currentNode.id
                          );
                          onAdd();
                        } else {
                          currentNode.code = code.current;
                          updateValue(code.current);
                          currentNode.error = undefined;
                          code.current = "";
                          roomId.current = "";
                          setDisabled(false);
                          setRowData([...dataRef.current]);
                        }
                      } else {
                        onAdd();
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        const currentNode = dataRef.current[tableMeta.rowIndex];
                        if (currentNode?.id === currentCodeRef.current) {
                          if (currentNode.code) onSave();
                        } else {
                          onAdd();
                        }
                      }
                    }}
                    placeholder="Enter Verification code*"
                    size="small"
                    inputProps={{
                      maxLength: 18,
                      sx: {
                        "&::placeholder": {
                          color: dataRef?.current[tableMeta.rowIndex]?.error
                            ? "#d32f2f"
                            : "#002c9a",
                        },
                      },
                    }}
                  />
                </div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "roomId",
      label: "Unit No.",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            style={{
              width: `${columnWidth}px`,
              border: "1px solid rgb(224, 224, 224)",
              padding: "16px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: "0.875rem", paddingTop: "0.2rem" }}>
                {"Unit No."}
                <IconButton
                  style={{ padding: 0, paddingLeft: "0.2rem" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if(openPopper && info === pageContent.unitNoInfo){
                      setPopper(false);  
                      popperState.current = "";
                    }else{
                      setPopper(true);
                      popperState.current = "UNIT";
                      setAnchorEl(e.currentTarget);
                      setInfo(pageContent.unitNoInfo);
                    }
                  }}
                >
                  <InfoOutlinedIcon color="secondary" />
                </IconButton>
              </div>
            </div>
          </th>
        ),
        setCellProps: (value) => {
          return {
            style: {
              borderRight: "1px solid rgba(224, 224, 224, 1)",
            },
          };
        },
        setCellHeaderProps: (value) => {
          return {
            style: {
              border: "1px solid rgba(224, 224, 224, 1)",
              width: `${columnWidth}px`,
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const onSave = () => {
            currentRoomRef.current = "";
            const currentCode = rowsData.find(
              (data) => data.id === tableMeta.rowData[0]
            );
            currentCode.roomId = value;
            code.current = "";
            roomId.current = "";
            setDisabled(false);
          };
          return (
            <>
              {tableMeta.rowData[3] !== undefined &&
                tableMeta.rowData[3] === false && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "0.4rem",
                      }}
                    >
                      {value}
                    </div>
                  </div>
                )}
              {(tableMeta.rowData[3] === undefined || tableMeta.rowData[3]) && (
                <div style={{ display: "flex" }}>
                  {
                    <Input
                      value={value}
                      sx={{ width: "100%" }}
                      onChange={(event) => {
                        updateValue(event.target.value);
                        const val = dataRef.current[tableMeta.rowIndex];
                        if (val) {
                          val.roomId = event.target.value;
                        } else {
                          handleRoomIdChange(event);
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          const currentNode =
                            dataRef.current[tableMeta.rowIndex];
                          if (currentNode?.id === currentRoomRef.current) {
                            onSave();
                          } else {
                            onAdd();
                          }
                        }
                      }}
                      placeholder="Enter Unit No."
                      size="small"
                      inputProps={{
                        maxLength: 18,
                        sx: {
                          "&::placeholder": {
                            color: "#002c9a",
                          },
                        },
                      }}
                    />
                  }
                </div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "error",
      viewColumns: false,
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "",
      viewColumns: true,
      options: {
        filter: false,
        sort: false,
        display: true,
        viewColumns: false,
        setCellHeaderProps: (value) => {
          return {
            style: {
              border: "1px solid #ffffff",
            },
          };
        },
        setCellProps: (value) => {
          return {
            style: {
              border: "1px solid #ffffff",
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            (tableMeta.rowData[3] === undefined || tableMeta.rowData[3]) && (
              <div>
                <Tooltip title={"Delete"}>
                  <IconButton
                    color="secondary"
                    onClick={() => onDelete(tableMeta)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )
          );
        },
      },
    },
  ];
  const options = {
    textLabels: {
      body: {
        noMatch: "",
      },
    },
    selectableRowsHideCheckboxes: true,
    enableNestedDataAccess: ".",
    expandableRows: false,
    expandableRowsHeader: false,
    elevation: 0,
    rowHover: false,
    responsive: "standard",
    fixedHeader: true,
    searchOpen: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    jumpToPage: false,
    filter: false,
    pagination: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          border: "1px solid rgba(224, 224, 224, 1)",
          borderTop: "none",
        },
      };
    },
  };

  const empty = () => {
    return [
      {
        id: `${Math.random()}#${code.current}#${roomId.current}`,
        code: "",
        roomId: "",
        error: undefined,
      },
    ];
  };

  useEffect(() => {
    setResult(false);
    setResultError(false);
    dataRef.current = empty();
    setRowData(dataRef.current);
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    refreshHeight();
  }, []);

  useEffect(() => {
    if (rowsData.length > 1) setDisabled(false);
  }, [rowsData]);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 320 && windowWidth <= 480) {
      setColumnWidth(200);
    } else if (windowWidth >= 480 && windowWidth <= 670) {
      setColumnWidth(240);
    } else {
      setColumnWidth(280);
    }
  }, [windowWidth]);

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    refreshHeight();
  };

  const refreshHeight = () => {
    setPageHeight(calculatedPageHeight());
  };

  const handleUIAfterResponse = (response) => {
    let failedCodes = response.failed;
    let alreadyClaimedCodes = response.existing;

    if (!failedCodes.length && !alreadyClaimedCodes.length) {
      const list = dataRef.current.filter((data) => data.code);
      const updatedList = list.map((data) => {
        data["error"] = false;
        return data;
      });
      setRowData(updatedList);
      dataRef.current = updatedList;
    } else {
      let updatedList = dataRef.current.map((data) => {
        const code = data.code
          .replaceAll(" ", "")
          .replaceAll(/\n/g, "")
          .toUpperCase();
        const processFailedCode = failedCodes
          .map((element) => element.replace(/\s/g, "").replace(/\n/g, ""))
          .filter((item) => item);
        if (processFailedCode.includes(code)) {
          data["error"] = true;
        } else if (alreadyClaimedCodes.includes(code)) {
          data["error"] = true;
        } else {
          data["error"] = !data.code && !data.roomId ? undefined : false;
        }
        return data;
      });

      dataRef.current = [...updatedList];
      setRowData([...dataRef.current]);
    }
  };

  const handleResponse = (response, codes) => {
    if (response) {
      handleMessage("", "");
      setPartialSuccess(false);
      setPartialSeverity("");
      setPartialSuccessMessage("");
      setPartialError([]);
      setPartialExistingError([]);
      let failedCodes = response.failed;
      let alreadyClaimedCodes = response.existing;

      //Combination
      if (failedCodes.length > 0 && alreadyClaimedCodes.length > 0) {
        failedCodes = failedCodes.map((item) => item.replaceAll(" ", ""));
        alreadyClaimedCodes = alreadyClaimedCodes.map((item) =>
          item.replaceAll(" ", "")
        );
        codes = codes.map((item) => item.replaceAll(" ", ""));
        const successCodes = codes.filter(
          (item) =>
            !failedCodes.includes(item.toUpperCase()) &&
            !alreadyClaimedCodes.includes(item.toUpperCase())
        );
        setPartialSuccess(true);
        if (successCodes.length > 0) {
          setPartialSeverity(pageContent.success);
          setPartialError(failedCodes);
          const success = successCodes.map((item) => ` ${item}`) + "";
          setPartialSuccessMessage(`${success} successfully added.`);
        } else {
          setPartialSeverity(pageContent.error);
        }

        setPartialError(failedCodes);
        setPartialExistingError(alreadyClaimedCodes);
        handleUIAfterResponse(response);

        return;
      }

      // Success and failure
      if (failedCodes.length > 0) {
        failedCodes = failedCodes.map((item) => item.replaceAll(" ", ""));
        codes = codes.map((item) => item.replaceAll(" ", ""));
        const successCodes = codes.filter(
          (item) => !failedCodes.includes(item.toUpperCase())
        );

        if (successCodes.length > 0) {
          setPartialSuccess(true);
          if (successCodes.length > 0) {
            setPartialSeverity(pageContent.success);
            setPartialError(failedCodes);
            const success = successCodes.map((item) => ` ${item}`) + "";
            setPartialSuccessMessage(`${success} successfully added.`);
          }
          setPartialError(failedCodes);
          handleUIAfterResponse(response);
          return;
        }
      }

      // Success and already claimed
      if (alreadyClaimedCodes.length > 0) {
        alreadyClaimedCodes = alreadyClaimedCodes.map((item) =>
          item.replaceAll(" ", "")
        );
        codes = codes.map((item) => item.replaceAll(" ", ""));
        const successCodes = codes.filter(
          (item) => !alreadyClaimedCodes.includes(item.toUpperCase())
        );

        if (successCodes.length > 0) {
          setPartialSuccess(true);
          if (successCodes.length > 0) {
            setPartialSeverity(pageContent.success);
            const success = successCodes.map((item) => ` ${item}`) + "";
            setPartialSuccessMessage(`${success} successfully added.`);
          }
          setPartialExistingError(alreadyClaimedCodes);
          handleUIAfterResponse(response);
          return;
        }
      }

      //Added Successfully
      if (failedCodes.length === 0 && alreadyClaimedCodes.length === 0) {
        if (codes.length > 1) {
          handleMessage(pageContent.success, pageContent.successMessage);
        } else {
          handleMessage(pageContent.success, pageContent.singleCodeSuccess);
        }

        setResult(true);
        setResultError(false);
        handleUIAfterResponse(response);
        return;
      }

      //Already added
      if (alreadyClaimedCodes.length > 0 && failedCodes.length === 0) {
        const codes = alreadyClaimedCodes.map((item) => ` ${item}`);
        if (codes.length > 1) {
          handleMessage(
            pageContent.info,
            `${codes} ${pageContent.alreadyClaimCode}`
          );
        } else {
          handleMessage(pageContent.info, pageContent.singleAlreadyClaim);
        }
        handleUIAfterResponse(response);
        return;
      }

      //Failure
      if (failedCodes.length > 0 && alreadyClaimedCodes.length === 0) {
        if (failedCodes.length > 1) {
          const codes = failedCodes.map((item) => ` ${item}`);
          handleMessage(
            pageContent.error,
            `${codes} ${pageContent.errorMessage}`
          );
        } else {
          handleMessage(
            pageContent.error,
            `${failedCodes} ${pageContent.singleCodeError}`
          );
        }
        handleUIAfterResponse(response);
        return;
      }
    }
  };

  const emptyCodeValidation = () => {
    const lists = dataRef.current.filter((data) => data.roomId && !data.code);
    const errorList = lists.map((data) => {
      data.error = true;
      data.isCodeEdit = true;
      return data;
    });
    setRowData([...dataRef.current]);
    return errorList?.length > 0 ? true : false;
  };

  const checkEmptyValue = () => {
    if (dataRef.current.length === 1) {
      const lists = dataRef.current.filter(
        (data) => !data.roomId && !data.code
      );
      const errorList = lists.map((data) => {
        data.error = true;
        data.isCodeEdit = true;
        return data;
      });
      setRowData([...dataRef.current]);
      return errorList?.length > 0 ? true : false;
    } else {
      const lists = dataRef.current.filter(
        (data) => !data.roomId && !data.code
      );
      if (dataRef.current.length === lists.length) {
        lists.map((data) => {
          data.error = true;
          data.isCodeEdit = true;
          return data;
        });
        setRowData([...dataRef.current]);
        return true;
      }

      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleMessage("", "");
    dataRef.current = dataRef.current?.filter(
      (data) => data?.error === undefined || data?.error
    );
    if (checkEmptyValue() || emptyCodeValidation()) {
      code.current = "";
      return;
    }
    setLoading(true);

    const codeData = {};
    dataRef.current?.forEach((data) => {
      if (data.code) {
        codeData[data.code.trim()] = data.roomId ?? "";
      }
    });
    const codes = Object.keys(codeData);
    const data = { codes: codeData };
    Provider.claimCodes(data)
      .then((response) => {
        handleResponse(response, codes);
      })
      .catch((error) => {
        resizeWindow();
        handleMessage(pageContent.error, textConstant.commonError);
      })
      .finally(() => {
        setRowData([...dataRef.current]);
        setLoading(false);
        setDisabled(false);
        code.current = "";
        roomId.current = "";
      });
  };

  const handleMessage = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
    setPartialSuccess(false);
  };

  const PartialSuccess = ({
    success,
    existingCodes,
    existingCodesErrorMessage,
    errorCodes,
    errorCodesMessage,
    severity,
  }) => {
    const errCodes = errorCodes.map((item) => ` ${item}`) + "";
    const existingErrorCodes = existingCodes.map((item) => ` ${item}`) + "";
    return (
      <Stack sx={{ width: "100%" }}>
        <Alert severity={severity}>
          {success && <Typography display="inline">{success}</Typography>}
          {existingCodes && existingCodes.length > 0 && (
            <>
              <Typography display="inline">{existingErrorCodes}</Typography>
              <Typography display="inline" sx={{ paddingLeft: 0.5 }}>
                {existingCodesErrorMessage}
              </Typography>
            </>
          )}
          {errorCodes && errorCodes.length > 0 && (
            <>
              <Typography display="inline" style={{ color: "#F94700" }}>
                {errCodes}
              </Typography>
              <Typography display="inline" sx={{ paddingLeft: 0.5 }}>
                {errorCodesMessage}
              </Typography>
            </>
          )}
        </Alert>
      </Stack>
    );
  };

  const splitText = (text) => {
    if (!text) {
      return [];
    }
    const newline = ["\n", "\t"];
    const isNewLine = newline.some((char) => text.includes(char));
    if (isNewLine && !text.includes(" ")) {
      return text
        .split("\n")
        .map((element) => element.trim())
        .filter((element) => element);
    } else if (text.includes(",") && text.includes("\n")) {
      return text
        .replace("\n", ",")
        .split(",")
        .map((element) => element.trim())
        .filter((element) => element);
    } else if (text.includes(",")) {
      return text
        .split(",")
        .map((element) => element.trim())
        .filter((element) => element);
    } else {
      return text
        .match(/.{1,7}(?:\s|$)/g)
        .map((element) => element.trim().replaceAll(",", ""))
        .filter((element) => element);
    }
  };

  const onDelete = (tableMeta) => {
    const id = tableMeta.rowData[0];
    dataRef.current = dataRef.current?.filter((data) => data.id != id);
    setRowData([...dataRef.current]);
  };

  const onAdd = () => {
    emptyCodeValidation();

    const codeItems = splitText(code.current);
    if (codeItems.length > 1) {
      const dataItems = [];
      codeItems.map((code) => {
        let roomVal = roomId.current;
        let codeVal = code;
        if (code.includes(",")) {
          const codeRoomObj = code.split(",");
          codeVal = codeRoomObj[0];
          roomVal = codeRoomObj[1];
        }
        const randomNo = Math.random();
        dataItems.push({
          id: `${randomNo}#${code.current}#${roomId.current}`,
          code: codeVal,
          roomId: roomVal?.trim(),
        });
      });

      if (dataRef.current.length > 1) {
        dataRef.current = dataRef.current.filter((data) => data.code);
        dataRef.current = [...dataRef.current, ...dataItems, ...empty()];
      } else {
        dataRef.current = [...dataItems, ...empty()];
      }

      setRowData([...dataRef.current]);
    } else {
      const randomNo = Math.random();
      if (!code.current && !roomId.current) {
        dataRef.current.push({
          id: `${randomNo}#${code.current}#${roomId.current}`,
          code: code.current ?? "",
          roomId: roomId.current ?? "",
          error: undefined,
        });
        setRowData([...dataRef.current]);
      } else {
        if (code.current || roomId.current) {
          dataRef.current = dataRef.current.filter((data) => data.code);
          dataRef.current.push({
            id: `${randomNo}#${code.current}#${roomId.current}`,
            code: code.current,
            roomId: roomId.current,
          });
          dataRef.current = [...dataRef.current, ...empty()];
        }
        setRowData([...dataRef.current]);
      }
    }

    code.current = "";
    roomId.current = "";
    if (inputError) {
      setInputError(false);
    }
  };

  const onClear = () => {
    dataRef.current = empty();
    setRowData([...dataRef.current]);
    code.current = "";
    roomId.current = "";
    currentCodeRef.current = undefined;
    currentRoomRef.current = undefined;
    setDisabled(false);
    handleMessage("", "");
    setResult(false);
    setTimeout(() => {
      resizeWindow();
    }, 0);
  };

  const onDone = () => {
    if (!isResultError) {
      onClear();
    } else {
      let updatedList = dataRef.current.filter(
        (data) => data.error || data.error === ""
      );
      updatedList = [...updatedList, ...data];
      setRowData(updatedList);
      dataRef.current = updatedList;
      setResult(false);
      handleMessage("", "");
      setTimeout(() => {
        resizeWindow();
      }, 0);
    }
  };


  return (
    <div
      style={{
        marginLeft: "1.5rem",
        paddingBottom: "1.5rem",
        width: "100%",
        height: "100%",
      }}
    >
      {loading ? <Loader /> : ""}
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <div
          style={{
            width: "97%",
            display: "flex",
          }}
        >
          <MUIDataTable
            data={rowsData}
            columns={columnsData}
            options={options}
          />
        </div>
        {!isResult && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ mt: 1 }}
          >
            <Tooltip title={"Add"}>
              <IconButton
                color="secondary"
                disabled={btnDisabled}
                onClick={() => onAdd()}
              >
                <AddCircleOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Typography
              variant="body2"
              color="#cfcfcf"
              sx={{ fontStyle: "italic" }}
            >
              {"Add new row"}
            </Typography>
          </Box>
        )}
      </form>
      {!isResult && (
        <>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={onSubmit}
          >
            {pageContent.submit}
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ mt: 2, ml: 2, mb: 2 }}
            onClick={onClear}
          >
            {"Clear"}
          </Button>
        </>
      )}
      {isResult && (
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={btnDisabled ? classes.disabledButton : ""}
          sx={{ mt: 2, mb: 2 }}
          onClick={onDone}
        >
          {"Done"}
        </Button>
      )}
      {message && severity ? (
        <Box sx={{ mt: 0 }}>
          <AlertDisplay severity={severity} message={message} />
        </Box>
      ) : null}
      {isPartialSuccess && (
        <Box sx={{ mt: 0 }}>
          <PartialSuccess
            success={partialSuccessMessage}
            existingCodes={partialExistingError}
            existingCodesErrorMessage={
              partialExistingError.length > 1
                ? pageContent.alreadyClaimCode
                : "is already added."
            }
            errorCodes={partialError}
            errorCodesMessage={
              partialError.length > 1
                ? pageContent.partialErrorMessage
                : pageContent.singleCodeError
            }
            severity={partialSeverity}
          />
        </Box>
      )}
        <Popper
          open={openPopper}
          anchorEl={anchorEl}
          placement={"bottom"}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper variant="outlined" display="inline-block">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent:'center',
                    alignItems:'center',
                    "& > :not(style)": {
                      m: 0.5,
                      mb:1,
                      width: 160,
                      height: 'auto',
                      overflow:'hidden'
                    },
                  }}
                >
                  <Typography variant={"caption"} sx={{ p: 1 }}>
                    {info}
                  </Typography>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
    </div>
  );
}
