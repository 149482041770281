import Amplify, { API, Auth } from "aws-amplify";
import { toBlob } from "html-to-image";
import download from "downloadjs";
import JSZip from "jszip";
import * as config from "../../src/hai.json";
import Util from "../util/Util";

const generateCodes = async (count) => {
  let response;
  try {
    response = await API.post("root", "/codes", {
      body: {
        count: Number.parseInt(count) ?? 1,
      },
    });
    console.log("Generate Codes Response *** ", response);
  } catch (error) {
    console.log(`Failed to generate codes`, error);
    throw error;
  }
  return response;
};

const generateQRCodes = async (file) => {
  let response;
  try {
    response = await API.post("ops", "/qr", {
      body: await file.text(),
    });
    console.log("Response is ", response);
  } catch (error) {
    console.log(`Failed to batch qr codes`, error);
    throw error;
  }
  return response;
};

const openFile = async () => {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";

    input.addEventListener("change", () => {
      resolve(input.files[0]);
    });
    input.click();
  });
};

const saveFile = async (blob, name) => {
  const a = document.createElement("a");
  a.download = name;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

const downloadQRCode = (code, callback) => {
  setTimeout(() => {
    try {
      const name = `QR-${code}`;
      requestAnimationFrame(() => {
        const element = document.getElementById(name);
        toBlob(element).then(function (dataUrl) {
          download(dataUrl, `${name}.tiff`);
          callback(true);
        });
      });
    } catch (error) {
      console.log(error);
      callback(false);
    }
  }, 0);
};

const downlodAllQrCodes = (codes, callback) => {
  setTimeout(async () => {
    try {
      let zip = new JSZip();
      let promises = [];
      codes.forEach(async (code) => {
        const element = document.getElementById(`QR-${code}`);
        promises.push(toBlob(element));
      });
      const imagesData = await Promise.all(promises);
      codes.forEach((code, index) => {
        zip.file(`${`QR-${code}`}.tiff`, imagesData[index], { binary: true });
      });
      const finalZip = await zip.generateAsync({ type: "blob" });
      download(finalZip, "QR-CODES.zip");
      callback(true);
    } catch (error) {
      callback(false);
      console.log(error);
    }
  }, 0);
};

const lookupDevices = async (data) => {
  let response;
  try {
    response = await API.post("root", "/search", {
      body: data,
    });
    console.log("Lookup Devices Response *** ", response);
  } catch (error) {
    console.log(`Failed to lookup devices`, error);
    throw error;
  }

  return response;
};

const downloadHistory = async (email) => {
  let response;
  try {
    response = await API.get("root", "/history", {
      queryStringParameters: {
        email: email,
        flatten: true,
      },
    });
    response = Util.unzip(response);
    console.log("Download History Response *** ", response);
  } catch (error) {
    console.log(`Failed to download history`, error);
    throw error;
  }

  return response;
};

const publishPackage = async (packageObj) => {
  let response;
  try {
    response = await API.post("root", "/firmware", {
      body: {
        version: packageObj.version,
        packages: packageObj.packages.split(","),
        minAppVersion: packageObj.minAppVersion,
        testVersion: packageObj.testVersion,
        critical:
          packageObj.critical && Number.parseInt(packageObj.critical)
            ? true
            : false,
        notifTitle: "Software Update Available",
        notifMessage:
          "A new software update is available for your hai showerhead. Tap to update now.",
      },
    });
    console.log("Publish Package Response *** ", response);
  } catch (error) {
    console.log(`Failed to publish fota version`, error);
    throw error;
  }

  return response;
};

const configure = async () => {
  //Amplify.Logger.LOG_LEVEL = 'DEBUG';
  const envConfig = Object.values(config)[0];
  console.log("Env Config is", envConfig);
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: envConfig?.region,
      //Amazon Cognito User Pool ID
      userPoolId: envConfig?.adminPoolId,
      //Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: envConfig?.adminClientId,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
    },
    API: {
      endpoints: [
        //IMPORTANT: Make sure the endpoints do NOT have a trailing / - this will mess up the call signature
        {
          name: "root",
          endpoint: `https://${envConfig.apiBaseEndpoint}/${envConfig.env}`,
          region: "us-east-1",
          custom_header: async () => {
            const token = (await Auth.currentSession())
              .getIdToken()
              .getJwtToken();
            console.log("Auth token is", token);
            return { Authorization: `Bearer ${token}` };
          },
        },
        {
          name: "ops",
          endpoint: process.env.REACT_APP_OPS_ENDPOINT,
          region: "us-east-1",
          custom_header: async () => {
            const token = (await Auth.currentSession())
              .getIdToken()
              .getJwtToken();
            console.log("Auth token is", token);
            return { Authorization: `Bearer ${token}` };
          },
        },
      ],
    },
  });

  // You can get the current config object
  const currentConfig = Auth.configure();
  const apiConfigure = API.configure();
};

const logOut = async () => {
  try {
    await Auth.signOut({ global: false });
  } catch (error) {
    console.log(`Failed to logout`, error);
  }
};

const groups = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.accessToken.payload["cognito:groups"];
}

const claimCodes = async (data) => {
  let response;
  try {
    response = await API.post("root", "/admin-codes", {
      body: data,
    });
    console.log("claimCodes Response *** ", response);
  } catch (error) {
    console.log(`Failed to claimCodes`, error);
    throw error;
  }
  return response;
};

const pairedDevices = async () => {
  let response;
  try {
    response = await API.get("root", "/admin-codes");
    console.log("Paired devices Response *** ", response);
  } catch (error) {
    console.log(`Failed to get paired devices `, error);
    throw error;
  }
  return response;
}

const note = async (serial,userId, text) => {
  let response;
  try {
    const path = `/admin-devices/${serial}/${userId}`;
    response = await API.put("root", path,{
      body: text
    });
    console.log("Note Response *** ", response);
  } catch (error) {
    console.log(`Failed to save note `, error);
    throw error;
  }
  return response;
}

const roomId = async (code, text) => {
  let response;
  try {
    const path = `/admin-codes/${code}`;
    response = await API.put("root", path,{
      body: text
    });
    console.log("Room id Response *** ", response);
  } catch (error) {
    console.log(`Failed to save room id `, error);
    throw error;
  }
  return response;
}

const unPairUser = async (userId, serial) => {
  let response;
  try {
    const path = `/admin-devices/${serial}/${userId}`;
    response = await API.del("root", path);
    console.log("Unpair user Response *** ", response);
  } catch (error) {
    console.log(`Failed to unpair user`, error);
    throw error;
  }
  return response;
}

const resetDevice = async (serial) => {
  let response;
  try {
    const path = `/admin-devices/${serial}/reset`;
    response = await API.del("root", path);
    console.log("Reset device Response *** ", response);
  } catch (error) {
    console.log(`Failed to reset device`, error);
    throw error;
  }
  return response;
}

const removeItem = async (code) => {
  let response;
  try {
    const path = `/admin-codes/${code}`;
    response = await API.del("root", path);
    console.log("Remove Item Response *** ", response);
  } catch (error) {
    console.log(`Failed to remove item`, error);
    throw error;
  }
  return response;
}

const dataDashboard = async () => {
  let response;
  try {
    response = await API.get("root", "/admin-data");
    console.log("Data dashboard Response *** ", response);
  } catch (error) {
    console.log(`Failed to get data dashboard url `, error);
    throw error;
  }
  return response;
}

export default {
  generateCodes,
  generateQRCodes,
  saveFile,
  downloadQRCode,
  downlodAllQrCodes,
  lookupDevices,
  downloadHistory,
  publishPackage,
  openFile,
  logOut,
  configure,
  groups,
  claimCodes,
  pairedDevices,
  note,
  roomId,
  unPairUser,
  resetDevice,
  removeItem,
  dataDashboard
};
