import React, { useState, useEffect } from "react";
import {
    Button,
    Typography
} from "@mui/material";
import Modal from "../components/Modal";
import Util from "../util/Util";

const FindProductInfo = () => {
    const productInfoLabelL1 =`Use the hai app to find the Product details of a nearby hai smart showerhead!`;
    const productInfoLabelL2 =`Tap the button below to open the hai app and use this feature.`; 
    const productInfoLabelL3 =`If the app is not installed, tap the button below to install it. You do not need to sign in to use this feature.`; 
    const productInfoLabelL4 =`Once installed, come back to this screen and tap the button again.`;
    const desktopInfoLabel = `This feature requires the hai mobile app, please visit this screen from your mobile device.`;
    const handleProductInfo = () => {
        window.location.href = Util.productInfoLink;
    }

    return (
        <div
            style={{
                marginLeft: "1.5rem",
                paddingBottom: "1.5rem",
                width: "100%",
                height: "100%",
            }}
        >
            {!Util.isMobile &&
                <Typography
                    color="secondary"
                    gutterBottom
                    sx={{ paddingRight: "1.5rem" }}
                >
                    {desktopInfoLabel}
                </Typography>
            }

            {
                Util.isMobile &&
                <>
                    <Typography
                        color="secondary"
                        gutterBottom
                        sx={{ paddingRight: "1.5rem" }}
                    >
                        {productInfoLabelL1}
                    </Typography>
                    <Typography
                        color="secondary"
                        gutterBottom
                        sx={{ paddingRight: "1.5rem" }}
                    >
                        {productInfoLabelL2}
                    </Typography>
                    <Typography
                        color="secondary"
                        gutterBottom
                        sx={{ paddingRight: "1.5rem" }}
                    >
                        {productInfoLabelL3}
                    </Typography>
                    <Typography
                        color="secondary"
                        gutterBottom
                        sx={{ paddingRight: "1.5rem" }}
                    >
                        {productInfoLabelL4}
                    </Typography>
                    <Button type="submit" size="small" color="secondary" variant="contained"
                        sx={{ mt: 2 }}
                        onClick={() => handleProductInfo()}>
                        Open hai App
                    </Button>
                </>
            }
        </div>
    )
}

export default FindProductInfo;
