import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Typography,
  TextField,
  Box,
  Button,
  Tooltip,
  Stack
} from "@mui/material";

import MUIDataTable from "@gethai/mui-datatables";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';

import Util from "../util/Util";
import Link from "@mui/material/Link";
import { QRCode } from "react-qr-svg";

import Modal from "../components/Modal";
import Loader from "../components/Loader";
import AlertDisplay from "../components/Alert";
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import Provider from "../services/Provider";

import { format } from "date-fns";
import debounce from 'lodash.debounce';
import { textConstant } from "../util/constants";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D3D3D3',
      },
      '&:hover fieldset': {
        borderColor: '#002c9a',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#002c9a',
      },
    },
  },
  headerCell:{
    '&.MuiTableCell-head':{
      '& span': {
        justifyContent:'center', 
        '& button':{
          marginLeft:0,
          marginRight:0,
          paddingLeft:0,
          paddingRight:0
        }
      }
    }
  }
}));

const PairedDeviceDashboard = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [isRemoveUserModal, setRemoveUserModal] = useState(false);
  const [removeUserText, setRemoveUserText] = useState("");
  const [isResetModal, setResetModal] = useState(false);
  const [isDeleteItemModal, setDeleteItemModal] = useState(false);
  const [isCommonModal, setCommonModal] = useState(false);
  const [rowsData, setRowData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [responsive, setResponsive] = useState("standard");
  const [qrCode, setQRCode] = useState("");
  const [isQRCodeModal, setQRCodeModal] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [selectedItem, setItem] = useState({});
  const [commonModalTitle, setCommonModalTitle] = useState("");
  const [commonModalText, setCommonModalText] = useState("");
  const [commonModalSuccess, setCommonModalSuccess] = useState(false);
  const [commonModalFailure, setCommonModalFailure] = useState(false);
  const [roomIdEditMode, setRoomIdMode] = useState("");
  const [noteEditMode, setNoteMode] = useState("");
  const notesRef = useRef();
  const roomIdRef = useRef();
  const [noDeviceFound, setNoDeviceFound] = useState(false);
  const [openPopper, setPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef();
  const dataRowsRef = useRef();
  const [searchText, setSearchText] = useState('');
  const [matchRecordText, setMatchRecordText] = useState('');
  const [noSearchResult, setSearchResult] = useState(false);
  const [isRowExpand, setRowExpand] = useState(false);
  const [rowExpandIndex, setRowExpandIndex] = useState('');
  const history = useHistory();

  const changeHandler = (value) => {
    setSearchResult(false);
    if (!value) {
      setRowData(dataRowsRef.current);
      setMatchRecordText(`${dataRowsRef.current?.length} records`);
      return;
    }
    const searchResults = dataRowsRef.current?.filter(item => {
      let obj = Object.values(item).slice(1, 7);
      const userProfiles = obj[4];
      if (userProfiles instanceof Array && userProfiles.length > 0) {
        userProfiles.map(data => {
          obj = obj?.concat(Object.values(data).slice(1, 5))?.concat(`${data?.firstName} ${data?.lastName}`);
        })
      }
      return obj.some(val => val?.toString().toLowerCase().includes(value?.toString().toLowerCase()));
    });

    setSearchResult(searchResults?.length === 0 ? true : false);
    setRowData(searchResults);
    if (searchResults?.length > 0) {
      setMatchRecordText(searchResults?.length === 1 ? `${searchResults?.length} matching record` : `${searchResults?.length} matching records`);
    }

    if (!searchText) {
      setMatchRecordText(searchResults?.length === 1 ? `${searchResults?.length} record` : `${searchResults?.length} records`);
    }

    closePopper();
  };

  const debouncedChangeHandler = useMemo(
    () => debounce((value) => {
      changeHandler(value);
    }, 250)
    , [searchText]);

  useEffect(() => {
    debouncedChangeHandler(searchText);
  }, [searchText]);

  useEffect(() => {
    setSearchResult(false);
    setText("Loading…");
    loadPairedDevices();
  }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("resize", resizeWindow);
      window.removeEventListener("keydown", handleEsc);
      debouncedChangeHandler.cancel();
    }
  }, []);

  useEffect(() => {
    if (windowWidth >= 320 && windowWidth <= 768) {
      setResponsive("vertical");
    } else {
      setResponsive("standard");
    }
  }, [windowWidth, rowsData]);

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const loadPairedDevices = () => {
    setLoading(true);
    handleMessage("", "");
    setNoDeviceFound(false);
    Provider.pairedDevices()
      .then((response) => {
        if (response && response.length > 0) {
          const rows = generateRows(response);
          const data = rows?.sort((a, b) => {
            if (a.roomId === "") return 1;
            if (b.roomId === "") return -1;
            if (a.roomId === b.roomId) return 0;
            return a.roomId.toLowerCase() > b.roomId.toLowerCase() ? 1 : a.roomId.toLowerCase() < b.roomId.toLowerCase() ? -1 : 0
          });
          if (searchText) {
            dataRowsRef.current = data;
            debouncedChangeHandler(searchText);
            return;
          }
          setRowData(data);
          dataRowsRef.current = data;
          setMatchRecordText(data?.length === 1 ? `${data?.length} record` : `${data?.length} records`);
        } else {
          setNoDeviceFound(true);
        }
      })
      .catch((error) => {
        handleMessage(textConstant.error, textConstant.commonError);
      })
      .finally(() => {
        setLoading(false);
        setText("");
      });
  };

  const generateRows = (items) => {
    try {
      if (items && items.length > 0) {
        return items.map((item) => {
          return {
            id: item.code,
            code: item.code,
            serial: item?.serial,
            resetAt: item?.resetAt
              ? format(new Date(item.resetAt), textConstant.dateTimeFormat)
              : "",
            resetState: item?.resetState,
            userProfile: item?.profiles,
            roomId: item?.tag,
          };
        });
      }
    } catch (error) {
      console.log("Failed to create table rows data " + error);
    }
  };

  const handleMessage = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
  };

  const options = {
    textLabels: {
      body: {
        noMatch: "No matching results",
      },
      pagination: {
        rowsPerPage: responsive === "standard" ? 'Rows per page:' : ""
      },
    },
    selectableRowsHideCheckboxes: true,
    enableNestedDataAccess: ".",
    expandableRows: true,
    pagination: false,
    expandableRowsOnClick: false,
    expandableRowsHeader: false,
    elevation: 0,
    responsive,
    fixedHeader: true,
    rowHover: responsive === "standard" ? true : false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    search: false,
    jumpToPage: false,
    isRowExpandIconVisible: (dataIndex, expandedRows) => {
      const indexMapped = [];
      rowsData.map((data, index) => {
        if (data?.userProfile.length > 0 && !indexMapped.includes(index)) {
          indexMapped.push(index);
        }
      });

      if (indexMapped.includes(dataIndex)) {
        return true;
      }
      return false;
    },
    renderExpandableRow: (data, rowMeta) => {
      closePopper();
      const columnsExpandData = [
        {
          name: "fullName",
          label: "User",
          viewColumns: responsive === "standard" ? true : false,
          options: {
            filter: false,
            sort: false,
            display: responsive === "standard" ? true : false,
            setCellHeaderProps: (cellValue) => {
              return ({ style: { display: data[1]?.length === 0 && 'none', borderBottom:'none', } })
            },
            setCellProps: () => {
              return {
                style:
                  responsive === "standard"
                    ? { minWidth: "100px", maxWidth: "45%", borderBottom:'none', borderTop: 'solid 1px rgba(0, 0, 0, 0.15)' }
                    : { width: "100%" },
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <div>
                    <Tooltip title={value ?? ""}>
                      <Typography
                        sx={{ marginTop: "0.5rem", fontSize: '0.875rem' }}
                        noWrap
                        gutterBottom
                      >
                        {tableMeta.rowData[2]}{" "}
                        {tableMeta.rowData[3]}
                      </Typography>
                    </Tooltip>
                  </div>
                </>
              );
            },
          },
        },
        {
          name: "email",
          label: "Email",
          viewColumns: responsive === "standard" ? true : false,
          options: {
            display: responsive === "standard" ? true : false,
            filter: false,
            sort: false,
            setCellHeaderProps: (cellValue) => {
              return ({ style: { display: data[1]?.length === 0 && 'none', borderBottom:'none', } })
            },
            setCellProps: () => {
              return {
                style:
                  responsive === "standard"
                    ? { minWidth: "100px", maxWidth: "45%", borderBottom:'none', borderTop: 'solid 1px rgba(0, 0, 0, 0.15)' }
                    : { width: "100%" },
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <div>
                    <Tooltip title={value ?? ""}>
                      <Typography
                        sx={{ marginTop: "0.5rem", fontSize: '0.875rem' }}
                        noWrap
                        gutterBottom
                      >
                        {value}
                      </Typography>
                    </Tooltip>
                  </div>
                </>
              );
            },
          },
        },
        {
          name: "firstName",
          viewColumns: false,
          options: {
            filter: false,
            sort: false,
            display: false,
            viewColumns: false,
          },
        },
        {
          name: "lastName",
          viewColumns: false,
          options: {
            filter: false,
            sort: false,
            display: false,
            viewColumns: false,
          },
        },
        {
          name: "note",
          label: "Notes",
          viewColumns: responsive === "standard" ? true : false,
          options: {
            filter: false,
            sort: false,
            display: responsive === "standard" ? true : false,
            setCellProps: () => {
              return {
                style:
                  responsive === "standard"
                    ? { minWidth: "100px", maxWidth: "45%", borderBottom:'none', borderTop: 'solid 1px rgba(0, 0, 0, 0.15)' }
                    : { width: "100%" },
              };
            },
            setCellHeaderProps: (cellValue) => {
              return ({ style: { display: data[1]?.length === 0 && 'none', borderBottom:'none', } })
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              if (tableMeta.rowData[6] === noteEditMode && notesRef.current) {
                value = notesRef.current;
              }
              const onSave = (e) => {
                e.stopPropagation();
                setNoteMode("");
                const rowData = tableMeta.tableData.find(k => k.id == tableMeta.rowData[6]);
                rowData.note = value;
                const userId = tableMeta.rowData[6]?.split('#')?.[0];
                const code = rowData.id.split("#")[1];
                const currentCell = rowsData.find(x => x.code === code);
                const currentProfile = currentCell?.userProfile.find(x => x.id === userId);
                currentProfile.note = value;
                setNote(currentCell.serial, userId, value);
              };

              const onEdit = (e) => {
                e.stopPropagation();
                closePopper();
                notesRef.current = "";
                setNoteMode(tableMeta.rowData[6]);
              };

              const onCancel = (e) => {
                e.stopPropagation();
                notesRef.current = "";
                setNoteMode("");
              }
              return (

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  {tableMeta.rowData[6] === noteEditMode && (
                    <>
                      <TextField
                        color="secondary"
                        autoFocus={true}
                        variant="standard"
                        size="small"
                        sx={{ mb: 1 }}
                        value={value}
                        inputProps={{ maxLength: 36 }}
                        onChange={(event) => { updateValue(event.target.value); notesRef.current = event.target.value }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            onSave(event);
                          }
                        }}
                      />
                      <Tooltip title="Save">
                        <IconButton onClick={onSave}>
                          <DoneIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton onClick={onCancel}>
                          <CloseIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {tableMeta.rowData[6] !== noteEditMode && (
                    <>
                      <Tooltip title={value ?? ""}>
                        <Typography
                          sx={{ marginTop: "0.5rem", fontSize: '0.875rem', color:value ? '#000000' : '#808080'  }}
                          noWrap
                          gutterBottom
                        >
                          {value ? value : 'None'}
                        </Typography>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton onClick={onEdit}>
                          <ModeEditIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              );
            },
          },
        },
        {
          name: "",
          label: "",
          viewColumns: responsive === "standard" ? true : false,
          options: {
            filter: false,
            sort: false,
            display: responsive === "standard" ? true : false,
            setCellHeaderProps: (cellValue) => {
              return ({ style: { display: data[1]?.length === 0 && 'none', borderBottom:'none', } })
            },
            setCellProps: () => {
              return {
                style:
                  responsive === "standard"
                    ? { minWidth: "100px", maxWidth: "10%", borderBottom:'none', borderTop: 'solid 1px rgba(0, 0, 0, 0.15)',  }
                    : { width: "100%" },
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              const onRemoveUser = (e) => {
                e.stopPropagation();
                closePopper();
                const currentData = tableMeta?.rowData[6].split('#');
                const userId = currentData?.[0];
                const code = currentData?.[1];
                const currentRow = rowsData.find(x => x.code === code);
                const selectedItem = {
                  serial: currentRow.serial,
                  userId: userId
                }
                setItem(selectedItem);
                handleMessage("", "");
                setRemoveUserText(
                  `This will remove ${tableMeta?.rowData[2]} ${tableMeta?.rowData[3]}(${tableMeta?.rowData[1]}) from accessing the showerhead. All current shower settings (and all other users) will remain as they are.`
                );
                setRemoveUserModal(true);
              };
              return (
                <Button
                  style={{ minHeight: "1rem" }}
                  onClick={onRemoveUser}
                  type="button"
                  color="secondary"
                  variant="contained"
                  size="small"
                >
                  Remove User
                </Button>
              );
            },
          },
        },
        {
          name: "id",
          viewColumns: false,
          options: {
            filter: false,
            sort: false,
            display: false,
            viewColumns: false,
          },
        },
        {
          name: "",
          label: "",
          viewColumns: responsive === "standard" ? false : true,
          options: {
            filter: false,
            sort: false,
            display: responsive === "standard" ? false : true,

            setCellHeaderProps: (cellValue) => {
              return ({ style: { display: data[1]?.length === 0 && 'none', } })
            },
            setCellProps: () => {
              return {
                style: { width: '100%', border: '1px solid rgba(224, 224, 224, 1)', }
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              if (tableMeta.rowData[6] === noteEditMode && notesRef.current || tableMeta.rowData[4]) {
                if (tableMeta.rowData[4]) {
                  value = tableMeta.rowData[4];
                }
                if (tableMeta.rowData[6] === noteEditMode && tableMeta.rowData[7] !== undefined) {
                  value = tableMeta.rowData[7];
                }
              }

              const onRemoveUser = (e) => {
                e.stopPropagation();
                closePopper();
                const currentData = tableMeta?.rowData[6].split('#');
                const userId = currentData?.[0];
                const code = currentData?.[1];
                const currentRow = rowsData.find(x => x.code === code);
                const selectedItem = {
                  serial: currentRow.serial,
                  userId: userId
                }
                setItem(selectedItem);
                handleMessage("", "");
                setRemoveUserText(
                  `This will remove ${tableMeta?.rowData[2]} ${tableMeta?.rowData[3]}(${tableMeta?.rowData[1]}) from accessing the showerhead. All current shower settings (and all other users) will remain as they are.`
                );
                setRemoveUserModal(true);
              }
              const onSave = (e) => {
                e.stopPropagation();
                setNoteMode("");
                const rowData = tableMeta.tableData.find(k => k.id == tableMeta.rowData[6]);
                rowData.note = value;
                const userId = tableMeta.rowData[6]?.split('#')?.[0];
                const code = rowData.id.split("#")[1];
                const currentCell = rowsData.find(x => x.code === code);
                const currentProfile = currentCell?.userProfile.find(x => x.id === userId);
                currentProfile.note = value;
                setNote(currentCell.serial, userId, value);
              };

              const onEdit = (e) => {
                e.stopPropagation();
                closePopper();
                notesRef.current = "";
                setNoteMode(tableMeta.rowData[6]);
              };

              const onCancel = (e) => {
                e.stopPropagation();
                notesRef.current = "";
                setNoteMode("");
              }
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginTop: '-1rem',
                    }}
                  >
                    <Typography
                      sx={{ marginTop: "0.8rem", fontSize: "1rem" }}
                      noWrap
                      gutterBottom
                    >
                      {"Name"}
                    </Typography>
                    <Typography
                      sx={{ marginTop: "0.2rem", fontSize: "0.875rem" }}
                      noWrap
                      gutterBottom
                    >
                      {tableMeta.rowData[2]} {tableMeta.rowData[3]}
                    </Typography>
                    <Typography
                      sx={{ marginTop: "0.8rem", fontSize: "1rem" }}
                      noWrap
                      gutterBottom
                    >
                      {"Email"}
                    </Typography>
                    <Typography
                      sx={{ marginTop: "0.2rem", fontSize: "0.875rem" }}
                      noWrap
                      gutterBottom
                    >
                      {tableMeta.rowData[1]}
                    </Typography>
                    <Typography
                      sx={{ marginTop: "0.8rem", fontSize: "1rem" }}
                      noWrap
                      gutterBottom
                    >
                      {"Notes"}
                    </Typography>
                    <Box
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-end"
                    >
                      {tableMeta.rowData[6] === noteEditMode && (
                        <>
                          <TextField
                            color="secondary"
                            autoFocus={true}
                            variant="standard"
                            size="small"
                            sx={{ mb: 1, width: '65%' }}
                            value={value}
                            inputProps={{ maxLength: 36 }}
                            onChange={(event) => {
                              updateValue(event.target.value);
                              notesRef.current = event.target.value;
                            }}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                onSave(event);
                              }
                            }}
                          />
                          <Tooltip title="Save">
                            <IconButton onClick={onSave}>
                              <DoneIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <IconButton onClick={onCancel}>
                              <CloseIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {tableMeta.rowData[6] !== noteEditMode && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title={value ?? ""}>
                            <Typography
                              sx={{ fontSize: "0.875rem", color:tableMeta.rowData[4] ? '#000000' : '#808080' }}
                              noWrap
                              gutterBottom
                            >
                              {tableMeta.rowData[4] ? tableMeta.rowData[4] : 'None'}
                            </Typography>
                          </Tooltip>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="Edit">
                              <IconButton onClick={onEdit}>
                                <ModeEditIcon color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </Box>
                    <Button
                      style={{ marginTop: "1rem", width: "8rem" }}
                      onClick={onRemoveUser}
                      type="button"
                      color="secondary"
                      variant="contained"
                      size="small"
                    >
                      Remove User
                    </Button>
                  </div>
                </>
              );
            },
          },
        },
      ];
      const rowsExpandData = data[1]?.map(k => { return { ...k, id: `${k.id}#${data[0]}` } }).sort((a,b) => {return a?.firstName < b?.firstName ? -1 : (a?.firstName > b?.firstName ? 1 : 0)});
      const optionsExpand = {
        selectableRowsHideCheckboxes: true,
        elevation: 0,
        responsive,
        pagination: false,
        search: false,
        download: false,
        print: false,
        filter: false,
        rowHover: false,
        viewColumns: false,
        setRowProps: (row, dataIndex, rowIndex) => {
          return {
            style: { border: 'none', },
          };
        },
        textLabels: {
          body: {
            noMatch: "",
          },
        },
      };
      return (
        <TableRow>
          <TableCell colSpan={10} sx={{ paddingLeft: responsive === "vertical" ? '2rem' : '5rem' }}>
            <MUIDataTable
              data={rowsExpandData}
              columns={columnsExpandData}
              options={optionsExpand}
            />
          </TableCell>
        </TableRow>
      );
    },
  };

  const columnsData = [
    {
      name: "id",
      viewColumns: false,
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "userProfile",
      viewColumns: false,
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "roomId",
      label: "Unit No.",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: value => ({ style: { textDecoration: 'underline', } }),
        setCellProps: (value) => {
          if(responsive === "vertical"){
            return {
              style: {
                borderBottom: responsive === "vertical" ? 'none' : '',
              },
            };
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const onSave = (e) => {
            e.stopPropagation();
            setRoomIdMode("");
            const code = tableMeta.rowData[3];
            const rowData = tableMeta.tableData.find((k) => k.code == code);
            if (rowData) {
              const currentCell = rowsData?.find((x) => x?.id == rowData?.id);
              currentCell.roomId = value;
              rowData.roomId = value;
              setRoomId(code, value);
              roomIdRef.current = "";
            }
          };

          const onEdit = (e) => {
            e.stopPropagation();
            closePopper();
            roomIdRef.current = { oldValue: value, code: tableMeta.rowData[3] };
            setRoomIdMode(tableMeta.rowData[0]);
          };

          const onCancel = (e) => {
            e.stopPropagation();
            roomIdRef.current = "";
            setRoomIdMode("");
          };
          return (
            <>
              {
                tableMeta.rowData[0] === roomIdEditMode && (
                  <div style={{ display: "flex" }}>
                    <TextField
                      color="secondary"
                      autoFocus={true}
                      variant="standard"
                      size="small"
                      sx={{ width: "8rem", mb: 1 }}
                      value={value}
                      inputProps={{ maxLength: 18 }}
                      onChange={(event) => updateValue(event.target.value)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          onSave(event);
                        }
                      }}
                    />
                    <div style={{ marginRight: "-1rem", display: "flex" }}>
                      <Tooltip title="Save">
                        <IconButton onClick={onSave}>
                          <DoneIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton onClick={onCancel}>
                          <CloseIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )
              }
              {
                tableMeta.rowData[0] !== roomIdEditMode && (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "95%" }}>
                      <Tooltip title={value ?? ""}>
                        <Typography sx={{ fontSize: "0.875rem", color:value ? '#000000' : '#808080' }} noWrap>
                          {value ? value : 'Not Assigned'}
                        </Typography>
                      </Tooltip>
                    </div>
                    <div style={{ width: "5%", }}>
                      <Tooltip style={{ padding: 0 }} title="Edit">
                        <IconButton style={{ padding: 0 }} onClick={onEdit}>
                          <ModeEditIcon style={{ padding: 0 }} color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )
              }
            </>
          );
        },
      },
    },
    {
      name: "code",
      label: "Verification Code",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: value => {
          if(responsive === "standard"){
            return {
              className: classes.headerCell,
              style: {
                textDecoration: 'underline',
              },
            };
          }
        },
        setCellProps: (value) => {
          if(responsive === "vertical"){
            return {
              style: {
                borderBottom: responsive === "vertical" ? 'none' : '',
              },
            };
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const onClick = (e) => {
            e.stopPropagation();
            closePopper();
            handleMessage("", "");
            const device = {
              serial: tableMeta?.rowData[4],
              code: tableMeta?.rowData[3],
            };
            const code = qrCodeContent(device);
            setQRCode(code);
            setQRCodeModal(true);
          };

          const onRemove = (e) => {
            e.stopPropagation();
            closePopper();
            const selectedItem = {
              serial: tableMeta.rowData[4],
              code: tableMeta?.rowData[3],
            };
            setItem(selectedItem);
            handleMessage("", "");
            setDeleteItemModal(true);
          };
          return (
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", display:'flex', justifyContent:responsive === "standard" ? 'center' : 'flex-start' }}>
                <Typography>
                  <Link
                    color="secondary"
                    variant="subtitle2"
                    onClick={onClick}
                    sx={{ fontSize: "0.875rem", textAlign:'center' }}
                    tabIndex={0}
                    component="button"
                  >
                    {value}
                  </Link>
                </Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "serial",
      label: "Product ID",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: value => {
          if(responsive === "standard"){
            return {
              className: classes.headerCell,
              style: {
                textDecoration: 'underline',
              },
            };
          }
        },
        setCellProps: (value) => {
          if(responsive === "vertical"){
            return {
              style: {
                borderBottom: responsive === "vertical" ? 'none' : '',
              },
            };
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                marginTop: responsive === "vertical" ? -20 : 0,
                marginBottom: responsive === "vertical" ? -20 : 0,
                justifyContent:responsive === "standard" ? 'center' : 'flex-start'
              }}
            >
              <Tooltip title={value ?? ""}>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    marginBottom: responsive === "standard" ? "0rem" : "0.2rem",
                    color:value ? '#000000' : '#808080' 
                  }}
                  noWrap
                  gutterBottom
                >
                 {value ? value : 'Not Paired'}
                </Typography>
              </Tooltip>{" "}
            </div>
          );
        }
      },
    },
    {
      name: "resetAt",
      label: "Last Reset",
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: value => {
          if(responsive === "standard"){
            return {
              className: classes.headerCell,
              style: {
                textDecoration: 'underline',
              },
            };
          }
        },
        setCellProps: (value) => {
          if(responsive === "vertical"){
            return {
              style: {
                borderBottom: responsive === "vertical" ? 'none' : '',
              },
            };
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "100%", display:'flex', justifyContent:responsive === "standard" ? 'center' : 'flex-start' }}>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {responsive === "standard" ? value : value ? value : "KEY||REMOVED"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        setCellProps: (value) => {
          if(responsive === "vertical"){
            return {
              style: {
                borderBottom: responsive === "vertical" ? 'none' : '',
              },
            };
          }else{
            return {
              style:{
               width:'20%',
              }
            }
          }          
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const onReset = (e) => {
            e.stopPropagation();
            closePopper();
            const selectedItem = {
              code: tableMeta.rowData[3],
              serial: tableMeta.rowData[4],
            };
            setItem(selectedItem);
            handleMessage("", "");
            setResetModal(true);
          };

          const onRemove = (e) => {
            e.stopPropagation();
            closePopper();
            const selectedItem = {
              serial: tableMeta.rowData[4],
              code: tableMeta?.rowData[3],
            };
            setItem(selectedItem);
            handleMessage("", "");
            setDeleteItemModal(true);
          };
          return (
            <div
              style={{
                display: "flex",
                justifyContent: responsive === "vertical" ? "flex-start" : "flex-end",
                marginTop: responsive === "vertical" ? -20 : 0,
                marginBottom: responsive === "vertical" ? -20 : 0,
              }}
            >
              {tableMeta.rowData[4] && (
                <div>
                  {(tableMeta.rowData[7] === undefined || (tableMeta.rowData[5] && tableMeta.rowData[7] === 0) || (tableMeta.rowData[5] && tableMeta.rowData[7] === 2)) && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={onReset}
                          type="button"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          color="secondary"
                          variant="contained"
                          size="small"
                        >
                          Reset Shower
                        </Button>
                        <IconButton
                          sx={{ visibility: "hidden", marginLeft: 0 }}
                        >
                          <InfoOutlinedIcon color="secondary" />
                        </IconButton>
                      </div>
                    </div>
                  )}
                  {tableMeta.rowData[7] === 1 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize:
                              responsive === "standard" ? "0.875rem" : "1rem",
                          }}
                          noWrap
                        >
                          Reset Pending
                        </Typography>
                        <IconButton
                          style={{marginRight: responsive === "standard" ? '0.6rem' : '0rem'}}
                          onClick={(e) => {
                            setPopper(false);
                            setAnchorEl(e.currentTarget);
                            if (popperRef.current == tableMeta.rowData[0]) {
                              setPopper(false);
                              popperRef.current = "";
                              return;
                            }
                            setPopper(true);
                            popperRef.current = tableMeta.rowData[0];
                          }}
                        >
                          <InfoOutlinedIcon color="secondary" />
                        </IconButton>
                       
                      </div>
                    </div>
                  )}
                  <Popper
                    open={openPopper}
                    anchorEl={anchorEl}
                    placement={"top"}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper variant="outlined">
                          <Box
                            sx={{
                              display: "flex",
                              "& > :not(style)": {
                                m: 1,
                                width: responsive === "standard" ? 150 : 180,
                                height: responsive === "standard" ? 80 : 60,
                              },
                            }}
                          >
                            <Typography
                              variant={"caption"}
                              sx={{ p: 1, pb: 3 }}
                            >
                              Reset has been initiated for this device. When the
                              next user pairs, the device will be reset to its
                              default factory settings.
                            </Typography>
                          </Box>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </div>
              )}
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'flex-start', alignItems:'center'}}>
                  <Button onClick={onRemove} 
                    type="button" 
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    color="secondary" 
                    variant="contained" 
                    size="small">
                    Delete Shower
                  </Button>
                </div>
            </div>
          );
        },
      },
    },
    {
      name: "resetState",
      viewColumns: false,
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const mobileColumnsData = [
    ...columnsData,
    {
      name: "",
      label: "",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: (value) => {
          return {
            style: {
              borderBottom: '1px solid #FFFFFF !important',
              paddingRight: 0
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue, onRowExpand, isExpandRow) => {
          const handleClick = (event, key) => {
            onRowExpand({ dataIndex: tableMeta?.rowIndex, index: tableMeta?.rowIndex });
            setRowExpand(!isExpandRow(tableMeta?.rowIndex) ? tableMeta?.rowIndex === key : false);
            setRowExpandIndex(tableMeta?.rowIndex === key ? tableMeta?.rowIndex : "");
          };

          if (tableMeta.rowData[1].length > 0) {
            return (
              <div style={{ display: "flex" }} onClick={(event) => handleClick(event, tableMeta?.rowIndex)}>
                <div style={{ width: "100%" }}>
                  <div style={{ marginTop: "0.1rem" }}>{"Users"}</div>
                </div>
                <div style={{paddingLeft: '5rem'}}>
                  <IconButton
                    key={tableMeta?.rowIndex}
                    style={{ padding: 0 }}
                  >
                    {!isExpandRow(tableMeta?.rowIndex) && (
                      <KeyboardArrowDownIcon />
                    )}
                    {isExpandRow(tableMeta?.rowIndex) && (
                      <KeyboardControlKeyIcon />
                    )}
                  </IconButton>
                </div>
              </div>
            );
          }
          return null;
        }
      },
    },
  ]

  const qrCodeContent = (device) => {
    return (
      device && (
        <div id={`device-${device.serial}`}>
          {device.serial && (
            <div style={{ display: "flex", marginBottom: "2px" }}>
              <div id="device-serial">{textConstant.productId}</div>
              <div
                className="code"
                style={{ marginLeft: "2px", color: "#002C9A" }}
              >
                {device.serial}
              </div>
            </div>
          )}
           {device.code && (
            <div style={{ display: "flex", marginBottom: "2px" }}>
              <div id="device-code">{textConstant.verificationCode}</div>
              <div style={{ marginLeft: "2px", color: "#002C9A" }}>
                {Util.formatCode(device.code)}
              </div>
            </div>
           )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <QRCode
              fgColor="#002C9A"
              style={{ width: 128, marginTop: 8 }}
              level="H"
              value={encodeURI(`${Util.appURL}?code=${device.code}`)}
            ></QRCode>
          </div>
          <Typography align="center" sx={{ paddingTop: '1rem' }}>
            <Button onClick={() => setQRCodeModal(false)} color="secondary" variant="contained">
              {textConstant.ok}
            </Button>
          </Typography>
        </div>
      )
    );
  };

  const resetDevice = () => {
    handleMessage("", "");
    setLoading(true);
    setCommonModalFailure(false);
    setCommonModalSuccess(false);
    Provider.resetDevice(selectedItem.serial)
      .then((response) => {
        loadPairedDevices();
        setCommonModal(true);
        setCommonModalTitle(textConstant.resetSuccessTitle);
        setCommonModalText(textConstant.resetSuccessDesc);
        setCommonModalSuccess(true);
      })
      .catch((error) => {
        setCommonModal(true);
        setCommonModalFailure(true);
        setCommonModalTitle("");
        setCommonModalText(textConstant.commonError);
        setLoading(false);
      })
      .finally(() => {
      });
  };

  const removeUser = () => {
    handleMessage("", "");
    setLoading(true);
    setCommonModalFailure(false);
    setCommonModalSuccess(false);
    Provider.unPairUser(selectedItem.userId, selectedItem.serial)
      .then((response) => {
        loadPairedDevices();
        setCommonModal(true);
        setCommonModalTitle(textConstant.removeSuccessTitle);
        setCommonModalText(textConstant.removeSuccessDesc);
        setCommonModalSuccess(true);
      })
      .catch((error) => {
        setCommonModal(true);
        setCommonModalTitle("");
        setCommonModalText(textConstant.commonError);
        setCommonModalFailure(true);
        setLoading(false);
      })
      .finally(() => {
      });
  };

  const setRoomId = (code, value) => {
    Provider.roomId(code, value)
      .then((response) => {
      })
      .catch((error) => {
        handleMessage(textConstant.error, textConstant.commonError);
      })
      .finally(() => { });
  };

  const setNote = (serial, userId, text) => {
    Provider.note(serial, userId, text)
      .then((response) => {
      })
      .catch((error) => {
        handleMessage(textConstant.error, textConstant.commonError);
      })
      .finally(() => { });
  };

  const deleteItem = () => {
    handleMessage("", "");
    setLoading(true);
    setCommonModalFailure(false);
    setCommonModalSuccess(false);
    Provider.removeItem(selectedItem.code)
      .then((res) => {
        loadPairedDevices();
      })
      .catch((error) => {
        setCommonModal(true);
        setCommonModalTitle("");
        setCommonModalText(textConstant.commonError);
        setCommonModalFailure(true);
        setLoading(false);
      })
      .finally(() => {
      });
  };

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      setRoomIdMode("");
      notesRef.current = "";
      roomIdRef.current = "";
      setNoteMode("");
      closePopper();
    }
  }

  const closePopper = () => {
    if (openPopper) {
      setPopper(false);
      popperRef.current = "";
    }
  }

  return (
    <div style={{ marginLeft: "0.5rem" }}>
      {loading ? <Loader text={text} /> : ""}
      <>
        {
          <div
            style={{
              marginTop: "0rem",
              display: "flex",
              justifyContent: "space-between",
              flexDirection:
                responsive === "standard" ? "row" : "column-reverse",
            }}
          >
            <Paper
              elevation={0}
              component={Stack}
              direction="column"
              justifyContent="center"
            >
              {noDeviceFound && (
                <div>
                  <Typography>
                    No records found. You can register devices from the{" "}
                    <Link
                      onClick={() => {
                        history.push("/add");
                      }}
                      style={{ cursor: "pointer" }}
                      color="secondary"
                    >
                      {" "}
                      Add Devices{" "}
                    </Link>{" "}
                    screen.
                  </Typography>
                </div>
              )}

              {noSearchResult && !noDeviceFound && (
                <Typography style={{ paddingTop: responsive === "standard" ? 0 : '0.5rem' }}>No matching records</Typography>
              )}

              {rowsData?.length > 0 && (
                <Typography style={{ paddingTop: responsive === "standard" ? 0 : '0.5rem' }}>{matchRecordText}</Typography>
              )}
            </Paper>
            {!noDeviceFound && (
              <div>
                <TextField
                  color="secondary"
                  variant="outlined"
                  placeholder={"Search"}
                  sx={{
                    mb: 1,
                    width: responsive === "standard" ? "24rem" : "100%",
                  }}
                  fullWidth={responsive === "standard" ? false : true}
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  inputProps={{ maxLength: 36 }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      debouncedChangeHandler(searchText);
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        style={{ paddingLeft: 0 }}
                        onClick={() => debouncedChangeHandler(searchText)}
                      >
                        <SearchOutlined />
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton>
                        {searchText.length > 0 ? (
                          <CancelIcon
                            onClick={() => {
                              setSearchText("");
                              setSearchResult(false);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        }
        {rowsData?.length > 0 && (
          <div style={{ marginTop: responsive === "standard" ? "0.5rem" : "1rem" }}>
            <MUIDataTable
              data={rowsData}
              columns={
                responsive === "standard" ? columnsData : mobileColumnsData
              }
              options={options}
            />
          </div>
        )}

        {message && severity ? (
          <Box sx={{ mt: 2 }}>
            <AlertDisplay severity={severity} message={message} />
          </Box>
        ) : null}
      </>
      <Modal
        open={isQRCodeModal}
        onClose={() => setQRCodeModal(false)}
        text={qrCode}
      />
      <Modal
        open={isRemoveUserModal}
        onClose={() => setRemoveUserModal(false)}
        title={removeUserText}
        text={textConstant.removeUserDesc}
        primaryBtnText={textConstant.yes}
        onPrimaryBtnClicked={() => {
          removeUser();
        }}
        secondaryBtnText={textConstant.no}
        onSecondaryBtnClicked={() => { }}
        isCheckboxEnable={false}
      />
      <Modal
        open={isResetModal}
        onClose={() => setResetModal(false)}
        title={textConstant.resetTitle}
        text={textConstant.resetDesc}
        primaryBtnText={textConstant.yes}
        onPrimaryBtnClicked={() => {
          resetDevice();
        }}
        secondaryBtnText={textConstant.no}
        onSecondaryBtnClicked={() => { }}
      />
      <Modal
        open={isCommonModal}
        onClose={() => setCommonModal(false)}
        title={commonModalTitle}
        text={commonModalText}
        primaryBtnText={textConstant.ok}
        onPrimaryBtnClicked={() => { }}
        isSuccess={commonModalSuccess}
        isError={commonModalFailure}
      />
      <Modal
        open={isDeleteItemModal}
        onClose={() => setDeleteItemModal(false)}
        title={
          selectedItem.serial ? "This will reset the shower and remove this device from the dashboard." : "This will remove this device from dashboard."
        }
        text={"Are you sure you want to delete?"}
        primaryBtnText={textConstant.yes}
        secondaryBtnText={textConstant.no}
        onPrimaryBtnClicked={() => {
          deleteItem();
        }}
        onSecondaryBtnClicked={() => { }}
      />
    </div>
  );
};

export default PairedDeviceDashboard;
