import React from "react";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";

export default function Help() {
  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <Typography color="secondary" gutterBottom>
        For any questions or concerns, please contact
        <Link
          sx={{ paddingLeft: 0.5 }}
          color="secondary"
          variant="subtitle2"
          tabIndex={0}
          component="button"
          onClick={() => window.location = 'mailto:haihelp@gethai.com'}
        >
          haihelp@gethai.com
        </Link>
        . We're here to help!
      </Typography>
    </div>
  );
}
