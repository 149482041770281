import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
const SnackbarControl = (props) => {
  const { open, severity, message } = props;
  const [openSnackbar, setOpenSnackbar] = useState(open);
  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar(false);
          }}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarControl;
