import React, { useState } from "react";
import { Typography, Container, TextField, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PublishIcon from "@mui/icons-material/Publish";
import Loader from "../components/Loader";
import Provider from "../services/Provider";
import Paper from "@mui/material/Paper";
import AlertDisplay from "../components/Alert";

const useStyle = makeStyles({
  inputField: {
    width: "50%",
  },
});

export default function PublishFirmware() {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState("");
  const [packages, setPackages] = useState("");
  const [critical, setCritical] = useState("");
  const [minAppVersion, setMinAppVersion] = useState("");
  const [testVersion, setTestVersion] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleMessage = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
  };

  const publishFirmware = (e) => {
    e.preventDefault();
    setLoading(true);
    handleMessage("", "");
    const obj = {
      version: version,
      packages: packages,
      critical: critical,
      minAppVersion: minAppVersion,
      testVersion: testVersion,
    };
    Provider.publishPackage(obj)
      .then((response) => {
        handleMessage("success", "Success! Notified " + response + " Users");
      })
      .catch((error) => {
        handleMessage("error", "Failed to Publish");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container sx={{ margin: "0 !important" }}>
      {loading ? <Loader /> : ""}
      <form noValidate autoComplete="off" onSubmit={publishFirmware}>
        <Typography color="secondary" gutterBottom>
          Enter Version
        </Typography>
        <TextField
          label="Version(XXX)"
          id="outlined-version"
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.inputField}
          value={version}
          onChange={(event) => {
            setVersion(event.target.value);
            handleMessage("", "");
          }}
          sx={{ mb: 2 }}
        />
        <Typography color="secondary" gutterBottom>
          Enter Package Name
        </Typography>
        <TextField
          label="Packages(b_X,a_XXX etc.)"
          variant="outlined"
          id="outlined-packages"
          color="secondary"
          size="small"
          className={classes.inputField}
          value={packages}
          onChange={(event) => {
            setPackages(event.target.value);
            handleMessage("", "");
          }}
          sx={{ mb: 2 }}
        />
        <br />
        <Typography color="secondary" gutterBottom>
          Enter Critical
        </Typography>
        <TextField
          label="Critical(Enter 1 if critical)"
          variant="outlined"
          color="secondary"
          size="small"
          className={classes.inputField}
          value={critical}
          onChange={(event) => {
            setCritical(event.target.value);
            handleMessage("", "");
          }}
          sx={{ mb: 2 }}
        />
        <br />
        <Typography color="secondary" gutterBottom>
          Enter Min App Version
        </Typography>
        <TextField
          label="Min App Version(Build Number)"
          variant="outlined"
          color="secondary"
          size="small"
          value={minAppVersion}
          className={classes.inputField}
          onChange={(event) => {
            setMinAppVersion(event.target.value);
            handleMessage("", "");
          }}
          sx={{ mb: 2 }}
        />
        <br />
        <Typography color="secondary" gutterBottom>
          Enter Test Version
        </Typography>
        <TextField
          label="Test Version(XXX)"
          variant="outlined"
          color="secondary"
          size="small"
          value={testVersion}
          onChange={(event) => {
            setTestVersion(event.target.value);
            handleMessage("", "");
          }}
          className={classes.inputField}
        />
        <br />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          size="small"
          sx={{ mt: 2 }}
          endIcon={<PublishIcon />}
        >
          Publish
        </Button>
      </form>
      {message && severity ? (
        <Box sx={{ mt: 2 }}>
          <AlertDisplay severity={severity} message={message} />
        </Box>
      ) : null}
    </Container>
  );
}
