import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  MenuItem,
  Menu,
  Backdrop,
  Collapse,
  ListItemButton,
} from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";

import PublishIcon from "@mui/icons-material/Publish";
import QrCodeIcon from "@mui/icons-material/QrCode";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import HistoryIcon from "@mui/icons-material/History";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ShowerIcon from '@mui/icons-material/Shower';
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpIcon from "@mui/icons-material/HelpOutline";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from '@mui/icons-material/Logout';
import LaunchIcon from '@mui/icons-material/Launch';
import Provider from "../services/Provider";
import logo from "../logo.png";

import { Auth } from "aws-amplify";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => {
 return ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width:0,
  [theme.breakpoints.up("sm")]: {
    width:0,
  }
}); 
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      paddingLeft: theme.spacing(0),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop,
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
    },
    active: {
      background: "#f4f4f4",
      color: "#002c9a",
    },
    inactive: {
      color: "rgba(0, 0, 0, 0.54)",
    },
    title: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontSize: "0.7rem !important",
    },
    logo: {
      width: 50,
      height: 25,
      textAlign: "center",
      display: "block",
      justifyContent: "center",
      margin: "auto",
      marginTop: 20,
    },
    toolbar: theme.mixins.toolbar,
    headerTitleOpen: {
      paddingLeft: "1%",
      flexGrow: 1,
      fontSize: "1.3rem !important",
    },
    headerTitleClosed: {
      paddingLeft:0,
      marginLeft:"-2rem",
      flexGrow: 1,
      fontSize: "1.3rem !important",
    },
  };
});

export default function Layout({ children, isInternalGroup }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userInfo, setUserInfo] = useState("");
  const [windowWidth, setWindowWidth] = useState(0);
  const [isBackdrop, setBackDrop] = useState(false);
  const [open, setOpen] = useState(true);
  const [innerItemOpen, setInnerItemOpen] = useState(false);
  const [selectedSubMenuItem, SetSubMenuItem] = useState("");
  const [drawerVariant, setDrawerVariant] = useState("");
  const theme = useTheme();

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    loadViewConfig();
  }, [windowWidth]);

  useEffect(() => {
    if (windowWidth >= 320 && windowWidth <= 900 && open) {
      setBackDrop(true);
    } else {
      setBackDrop(false);
    }
  }, [open]);

  useEffect(() => {
    if (windowWidth >= 320 && windowWidth <= 1024) {
      setOpen(false);
      setDrawerVariant("persistent");
    } else {
      setOpen(true);
      setBackDrop(false);
      setDrawerVariant("permanent");
    }
  }, [windowWidth]);

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const loadViewConfig = () => {
    if (windowWidth >= 320 && windowWidth <= 900) {
      setOpen(false);
      setDrawerVariant("persistent");
    } else {
      setOpen(true);
      setBackDrop(false);
      setDrawerVariant("permanent");
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
    setInnerItemOpen(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setInnerItemOpen(false);
  };

  const menuItems = [
    {
      text: "Codes",
      path: "/",
      isInternalGroup: true,
      isSubItem: false,
    },
    {
      text: "Add Devices",
      path: "/add",
      isInternalGroup: false,
      isSubItem: false,
    },
    {
      text: "Paired Devices",
      path: "/paireddevice",
      isInternalGroup: true,
      isSubItem: false,
    },
    {
      text: "Manage Devices",
      path: "/manage",
      isInternalGroup: false,
      isSubItem: false,
    },
    {
      text: "Data Dashboard",
      path: "/data",
      isInternalGroup: false,
      isSubItem: true,
      subItems: [
        {
          id: 1,
          text: "Shower Metrics",
          screen: 'Shower_Metrics',
          path: "/shower",
        },
        {
          id: 2,
          text: "User Metrics",
          screen: 'User_Metrics',
          path: "/user",
        },
      ],
    },
    {
      text: "Data Dashboard",
      path: "/data",
      isInternalGroup: true,
    },
    {
      text: "History",
      path: "/history",
      isInternalGroup: true,
      isSubItem: false,
    },
    {
      text: "Publish Firmware",
      path: "/publishfirmware",
      isInternalGroup: true,
      isSubItem: false,
    },
    {
      text: "Find Product ID",
      path: "/product",
      isInternalGroup: true,
      isSubItem: false,
    },
    {
      text: "Find Product ID",
      path: "/product",
      isInternalGroup: false,
      isSubItem: false,
    },
    {
      text: "Get Help",
      path: "/help",
      isInternalGroup: false,
      isSubItem: false,
    },
    {
      text: "Sign out",
      path: "/signout",
      isInternalGroup: true,
      isSubItem: false,
    },
    {
      text: "Sign out",
      path: "/signout",
      isInternalGroup: false,
      isSubItem: false,
    },
  ];

  const renderIcon = (item, isActive) => {
    switch (item.path) {
      case "/":
        if (isInternalGroup)
          return isActive ? <QrCodeIcon color="secondary" /> : <QrCodeIcon />;
        else return isActive ? <AddIcon color="secondary" /> : <AddIcon />;
      case "/add":
        return isActive ? <AddIcon color="secondary" /> : <AddIcon />;
      case "/paireddevice":
      case "/manage":
        return isActive ? (
          <MobileFriendlyIcon color="secondary" />
        ) : (
          <MobileFriendlyIcon />
        );
      case "/muidatatable":
        return isActive ? (
          <MobileFriendlyIcon color="secondary" />
        ) : (
          <MobileFriendlyIcon />
        );
      case "/history":
        return isActive ? <HistoryIcon color="secondary" /> : <HistoryIcon />;
      case "/publishfirmware":
        return isActive ? <PublishIcon color="secondary" /> : <PublishIcon />;
      case "/data":
        return isActive ? (
          <DashboardIcon color="secondary" />
        ) : (
          <DashboardIcon />
        );
      case "/help":
        return isActive ? <HelpIcon color="secondary" /> : <HelpIcon />;
      case "/shower":
        return isActive ? <ShowerIcon color="secondary" /> : <ShowerIcon />;
      case "/user":
          return isActive ? <SwitchAccountIcon color="secondary" /> : <SwitchAccountIcon />;
      case "/signout":
        return isActive ? <LogoutIcon color="secondary" /> : <LogoutIcon />;
      case "/product":
          return isActive ? <LaunchIcon color="secondary" /> : <LaunchIcon />;
    }
  };

  const handleClick = (item) => {
    if (isBackdrop) handleDrawerClose();
    if(item.path === "/signout"){
      handleLogout();
      return;
    }

    setTitle(item.text);
    history.push(item.path);
    //TODO: Data Dashboard
    // setInnerItemOpen(item.isSubItem ? !innerItemOpen : false);
    // SetSubMenuItem(menuItems[4]?.subItems[0]);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    Provider.logOut()
      .then(() => {
        history.replace("", null);
      })
      .catch((err) => {});
  };

  useEffect(async () => {
    if (location.pathname === "/") {
      const groups = await Provider.groups();
        if(document.referrer)
          setTitle(menuItems[7].text);
        else
          setTitle(groups !== undefined && groups.length > 0 ? menuItems[0].text : menuItems[3].text);
    } else {
      menuItems.map((item) =>
        item.path === location.pathname ? setTitle(item.text) : null
      );
    }
    let userInfo = await Auth.currentAuthenticatedUser();
    if (userInfo) {
      setUserInfo(userInfo.attributes?.email);
    }
    SetSubMenuItem(menuItems[4]?.subItems[0]);
  }, []);

  useEffect(() => {
    if (location.pathname != "/") {
      menuItems.map((item) =>
        item.path === location.pathname ? setTitle(item.text) : null
      );
    }
  }, [location]);

  const childrenWithProps = React.Children.map(children, (child) => {
    return  React.cloneElement(child);
  });

  return (
    <div className={classes.root}>
      {/* app bar */}
      <AppBar position="fixed" open={open} color="secondary">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            varient="h1"
            noWrap
            component="div"
            className={
              open ? classes.headerTitleOpen : classes.headerTitleClosed
            }
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* side drawer */}
      <Drawer variant={drawerVariant} open={open}>
        <DrawerHeader>
          {open ? (
            <div>
              <img src={logo} className={[classes.logo]} alt="logo" />
              <Typography
                varient="h5"
                className={classes.title}
                color="secondary"
              >
                {userInfo}
              </Typography>
            </div>
          ) : (
            ""
          )}

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon fontSize="small" />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </IconButton>
        </DrawerHeader>

        <List sx={{ marginTop: open ? "0px" : "10px" }}>
          {menuItems.map(
            (item) =>{
                return item.isInternalGroup === isInternalGroup && (
                <div  key={item.text}>
                  <ListItem
                    onClick={() => handleClick(item)}
                    sx={{ cursor: "pointer" }}
                    className={
                      location.pathname == item.path
                        ? classes.active
                        : classes.inactive
                    }
                  >
                    <ListItemIcon>
                      {renderIcon(item, location.pathname == item.path)}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                  {item.isSubItem && (
                    <Collapse in={innerItemOpen} timeout="auto" unmountOnExit>
                      <List sx={{mt: -1}}>
                        {item.subItems.map((subItem) => (
                          <ListItem
                            key={subItem.id}
                            onClick={() => {
                              SetSubMenuItem(subItem);
                            }}
                            sx={{ cursor: "pointer" }}
                            className={
                              subItem.id == selectedSubMenuItem.id
                                ? classes.active
                                : classes.inactive
                            }
                          >
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </div>
                )
              }
              
          )}
        </List>
      </Drawer>

      {/* main content */}
      <Backdrop
        sx={{
          ml: `${drawerWidth}px`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isBackdrop}
      />
      <Main
        open={open}
        sx={{
          marginLeft: open ? "10px" : "-15px",
          width: "100%",
          height: "100%",
          overflow: "hidden !important",
        }}
      >
        <DrawerHeader />
        {childrenWithProps}
      </Main>
    </div>
  );
}
