const columns = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.1,
    hideable: false,
    headerAlign: "center",
    align: "center",
    hideable: false,
    hide: true,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 0.2,
    hide: true,
    headerAlign: "center",
  },
  {
    field: "productId",
    headerName: "Product ID",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "latitude",
    headerName: "Latitude",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "longitude",
    headerName: "Longitude",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "showerDate",
    headerName: "Shower Date",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "showerTime",
    headerName: "Shower Time",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "volume",
    headerName: "Volume",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "temperature",
    headerName: "Temperature",
    flex: 0.1,
    headerAlign: "center",
    align: "center",
  },
];

const rows = (items) => {
  try {
    if (items && items.length > 0) {
      return items.map((item) => {
        const items = item.split(",");
        return {
          id: Math.random(),
          email: items[0],
          productId: items[1],
          latitude: items[2],
          longitude: items[3],
          showerDate: items[4],
          showerTime: items[5],
          duration: items[6],
          volume: items[7],
          temperature: items[8],
        };
      });
    }
  } catch (error) {
    console.log("Failed to create table rows data " + error);
  }
};

export default {
  columns,
  rows,
};
