import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const TableView = (props) => {
  let { rows, columns, pageSize, styles, onCellClick, toolbar, rowHeight = 60, columnSelector = false, disableVirtualization = false } = props;
  const [page, setPage] = useState(pageSize);
  const height = window.innerHeight ? window.innerHeight * 0.7 : 400;

  return (
    <>
      <div style={{ height: height, width: "100%", marginTop: 20 }}>
        <DataGrid
          className={styles}
          onCellClick={onCellClick}
          rowHeight={rowHeight}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          pageSize={page}
          onPageSizeChange={(newPage) => setPage(newPage)}
          components={{ Toolbar: toolbar }}
          pagination
          disableColumnSelector={columnSelector}
          disableVirtualization= {disableVirtualization}
        />
      </div>
    </>
  );
};

export default TableView;
