import {
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";

export default function Loader({text}) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
        <Box>
          <Typography>{text ? text : 'Processing, please wait…'}</Typography>
        </Box>
      </Stack>
    </Backdrop>
  );
}
