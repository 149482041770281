import React, { useState, memo } from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert } from "@mui/material";

const Modal = ({
  open,
  onClose,
  title,
  text,
  primaryBtnText,
  onPrimaryBtnClicked,
  secondaryBtnText,
  onSecondaryBtnClicked,
  isCheckboxEnable = false,
  checkBoxLabel,
  checkBoxWarningText,
  onCheckboxChanged,
  isSuccess = false,
  isError = false
}) => {
  const [isChecked, setCheckbox] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      {title && (
        <DialogTitle component={"span"}>
          <Typography component={"span"} gutterBottom>
            {title}
          </Typography>
        </DialogTitle>
      )}

      {
        text && isSuccess && (
          <DialogContent>
          <DialogContentText component={"span"}>
            <Alert severity={'success'}>{text}</Alert>
          </DialogContentText>
        </DialogContent>
        )
      }

      {
        text && isError && (
          <DialogContent>
          <DialogContentText component={"span"}>
            <Alert severity={'error'}>{text}</Alert>
          </DialogContentText>
        </DialogContent>
        )
      }  

      {text && !isSuccess && !isError && (
        <DialogContent>
          <DialogContentText component={"span"}>
            <Typography component={"span"} color="#000000" gutterBottom>
              {text}
            </Typography>
          </DialogContentText>
        </DialogContent>
      )}

      {isCheckboxEnable && checkBoxLabel && (
        <>
          <FormGroup style={{ marginLeft: "4rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={isChecked}
                  onChange={() => {
                    setCheckbox(!isChecked);
                    onCheckboxChanged(!isChecked);
                  }}
                />
              }
              label={checkBoxLabel}
            />
          </FormGroup>
          {isChecked && (
            <Typography
              style={{ marginLeft: "1.5rem" }}
              component={"span"}
              color="#F94700"
              gutterBottom
            >
              {checkBoxWarningText}
            </Typography>
          )}
        </>
      )}

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: 10,
        }}
      >
        {onSecondaryBtnClicked && secondaryBtnText && (
          <Button
            autoFocus
            onClick={() => {
              onClose();
              onSecondaryBtnClicked();
            }}
            color="secondary"
            variant="contained"
          >
            {secondaryBtnText}
          </Button>
        )}

        {onPrimaryBtnClicked && primaryBtnText && (
          <Button
            onClick={() => {
              onClose();
              onPrimaryBtnClicked();
            }}
            autoFocus
            color="secondary"
            variant="contained"
          >
            {primaryBtnText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default memo(Modal);
