import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Card,
  Box,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import { QRCode } from "react-qr-svg";
import Loader from "../components/Loader";
import Provider from "../services/Provider";
import AlertDisplay from "../components/Alert";
import SnackbarControl from "../components/Snackbar";
import Util from "../util/Util";

const useStyle = makeStyles({
  disabledButton: {
    backgroundColor: "#cfcfcf !important",
    pointerEvents: "none",
  },
  qrDetails: {
    textAlign: "left",
    marginTop: "10.2px",
  },
  codeCaption: {
    fontSize: "7pt",
    marginBottom: "6px",
  },
});

export default function Codes() {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setDisabled] = useState(true);
  const [alignment, setAlignment] = useState("generate");
  const [count, setCount] = useState();
  const [codes, setCodes] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [state, setState] = React.useState({
    open: false,
    severity: "",
    message: "",
  });

  const calculatedHeight = () => `${window.innerHeight - 100}px`;
  const innerHeight = calculatedHeight();
  const [height, setHeight] = useState(innerHeight);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const resizeWindow = () => {
    setHeight(calculatedHeight());
  };

  const handleMessage = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
  };

  const generateCodes = (e) => {
    e.preventDefault();
    setLoading(true);
    setCodes([]);
    setState({
      open: false,
      severity: "success",
      message: "",
    });
    handleMessage("", "");
    Provider.generateCodes(count)
      .then((response) => {
        if (response && response.length <= 10) {
          setCodes(response);
        } else {
          Provider.saveFile(
            new Blob([response.join("\r\n")], { type: "text/csv" }),
            "codes.txt"
          );

          handleMessage("success", "Codes downloaded successfully");
        }
      })
      .catch((error) => {
        handleMessage("error", "Failed to generate codes");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadQRCode = (event, code) => {
    event.preventDefault();
    setLoading(true);
    setState({
      open: false,
      severity: "success",
      message: "",
    });
    Provider.downloadQRCode(code, (status) => {
      if (status) {
        const message = `QR Code ${Util.formatCode(
          code
        )} downloaded successfully`;
        setState({
          open: true,
          severity: "success",
          message: message,
        });
      } else {
        setState({
          open: true,
          severity: "error",
          message:
            "Unable to download QR code. Something went wrong, please try again",
        });
      }
      setLoading(false);
    });
  };

  const downlodAllQrCodes = (e) => {
    e.preventDefault();
    setLoading(true);
    setState({
      open: false,
      severity: "success",
      message: "",
    });
    Provider.downlodAllQrCodes(codes, (status) => {
      if (status) {
        const message = `QR Code downloaded successfully`;
        setState({
          open: true,
          severity: "success",
          message: message,
        });
      } else {
        setState({
          open: true,
          severity: "error",
          message:
            "Unable to download all QR codes. Something went wrong, please try again",
        });
      }
      setLoading(false);
    });
  };

  const generateQRCodes = async (e) => {
    e.preventDefault();
    setCodes([]);
    handleMessage("", "");
    setState({
      open: false,
      severity: "success",
      message: "",
    });

    const file = await Provider.openFile();
    if (!file) {
      return;
    }
    setLoading(true);
    Provider.generateQRCodes(file)
      .then((response) => {
        setCodes(response);
      })
      .catch((error) => {
        handleMessage("error", "Failed to batch qr codes");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    handleMessage("", "");
    setState({
      open: false,
      severity: "success",
      message: "",
    });
  };

  const handTextChange = (e) => {
    e.preventDefault();
    if (e.target.value.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setCount(e.target.value);
    handleMessage("", "");
    setState({
      open: false,
      severity: "success",
      message: "",
    });
  };

  const UploadCode = () => {
    return (
      <>
        <form noValidate autoComplete="off" onSubmit={generateQRCodes}>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            endIcon={<UploadFileIcon />}
          >
            Upload Text Codes
          </Button>
        </form>
      </>
    );
  };

  const ViewCodes = (codes) => {
    return (
      <>
        {codes.codes !== undefined && codes.codes.length > 0 ? (
          <Container sx={{ py: 2, ml: -3 }}>
            <Grid sx={{ mt: -1 }} container spacing={4}>
              {codes.codes.map((code, index) => {
                return (
                  <Grid item key={code}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <div
                          key={index}
                          className="qr-container"
                          id={`QR-${code}`}
                        >
                          <QRCode
                            style={{ width: 138.6144 }}
                            bgColor="transparent"
                            fgColor="black"
                            level="H"
                            value={encodeURI(`${Util.appURL}?code=${code}`)}
                          ></QRCode>
                          <div className={classes.qrDetails}>
                            <div
                              className={classes.codeCaption}
                              id="qr-caption"
                            >
                              Verification Code:
                            </div>
                            <div className="code">{Util.formatCode(code)}</div>
                          </div>
                        </div>
                        <Button
                          sx={{ mt: 1 }}
                          onClick={(event) => {
                            downloadQRCode(event, code);
                          }}
                          color="secondary"
                          variant="contained"
                          endIcon={<DownloadIcon />}
                        >
                          Download
                        </Button>
                      </CardContent>
                    </Card>
                    <SnackbarControl {...state} />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        ) : null}
      </>
    );
  };

  return (
    <div
      style={{
        paddingBottom: "1.5rem",
        width: "100%",
        maxHeight: height,
        height: "auto",
        overflowY: "auto",
      }}
    >
    <Container sx={{ margin: "0 !important" }}>
      {loading ? <Loader /> : ""}
      <ToggleButtonGroup
        color="secondary"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="generate">Generate Codes</ToggleButton>
        <ToggleButton value="upload">Upload Text Codes</ToggleButton>
      </ToggleButtonGroup>
      <br />
      <br />
      {alignment === "generate" ? (
        <>
          <Typography
            gutterBottom
            color="secondary"
            sx={{
              marginBottom: "0.5rem",
            }}
          >
            Enter Count
          </Typography>
          <form noValidate autoComplete="off" onSubmit={generateCodes}>
            <TextField
              label="Count"
              variant="outlined"
              type="number"
              value={count}
              autoFocus
              size="small"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 1 }}
              onChange={handTextChange}
              color="secondary"
              required
            />
            <br />
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ mt: 2 }}
              className={btnDisabled ? classes.disabledButton : ""}
              endIcon={<SendIcon />}
            >
              Generate
            </Button>
            {codes !== undefined && codes.length > 1 ? (
              <Button
                sx={{ mt: 2, ml: 2 }}
                color="secondary"
                variant="contained"
                onClick={downlodAllQrCodes}
              >
                Download All
              </Button>
            ) : null}
          </form>
        </>
      ) : (
        <UploadCode />
      )}

      {message && severity ? (
        <Box sx={{ mt: 2 }}>
          <AlertDisplay severity={severity} message={message} />
        </Box>
      ) : null}

      {alignment === "generate" ? <ViewCodes codes={codes} /> : null}
    </Container>
    </div>
  );
}
